@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@300;400;500;600;700&display=swap');

:root {
  /* Paleta de color BKUIC */
  /* AZUL PRIMARIO */
  --azul-primario: #3C8AFF;
  /* Primario L1 */
  --azul-primariol1: #AECEFF;
  /* Primario L2 */
  --azul-primariol2: #7BAEFC;
  /* Primario D2 */
  --azul-primariod2: #2762B8;
  /* Primario D1 */
  --azul-primariod1: #2F72D2;

  /* TURQUESA PRIMARIO*/
  --turquesa: #35C8DC;
  /* Turquesa L1 */
  --turquesal1: #38D1E7;
  /* Turquesa L3 */
  --turquesal3: #9BE4EE;
  /* Turquesa D1 */
  --turquesad1: #31B0C3;
  /* Turquesa D2 */
  --turquesad2: #2097A9;
  /* Turquesa D3 */
  --turquesad3: #1F7783;
  /* Turquesa D4 */
  --turquesad4: #083940;


  /* GRISES */
  /* Gris */
  --gris: #8497B8;
  /* Gris 100 */
  --dark: #081F41;
  /* Inactivo */
  --inactivo: #9FACC1;
  /* Gris 70 */
  --gris-obscuro3: #363B42;
  /* Gris 60 */
  --gris-obscuro2: #6B7482;
  /* Gris 50 */
  --gris-obscuro1: #9FACC1;
  /* Gris 40 */
  --gris-claro4: #B0BED4;
  /* Gris 30 */
  --gris-claro3: #CCD4E3;
  /* Gris 20 */
  --gris-claro2: #E1E6EF;
  /* Gris 10 */
  --gris-claro: #F1F4F9;
  /* Blanco */
  --blanco: #fff;


  /* ACENTOS DE COLOR */
  /* Magenta*/
  --magenta: #F944F4;
  /* Magenta D1 */
  --magentad1: #DF3CDA;
  /* Magenta D2 */
  --magentad2: #531153;


  /* Rojo alerta */
  --rojo-alerta: #FF644A;
  /* Rojo alerta L1 */
  --rojo-alertal1: #FF7B65;
  /* Rojo alerta L3 */
  --rojo-alertal3: #FFBCB1;
  /* Rojo alerta D1 */
  --rojo-alertad1: #DB4A35;
  /* Rojo alerta D2 */
  --rojo-alertad2: #9B1E1E;
  /* Rojo alerta D3 */
  --rojo-alertad3: #711616;
  /* Amarillo alerta */
  --amarillo-alerta: #FFE32C;
  /* Amarillo alerta L1 */
  --amarillo-alertal1: #FFE751;
  /* Amarillo alerta D1 */
  --amarillo-alertad1: #DDBC23;
  /* Amarillo alerta D2 */
  --amarillo-alertad2: #8C7B26;
  /* Amarillo alerta D3 */
  --amarillo-alertad3: #3B320A;
  /* Verde alerta */
  --exito: #BCE15F;
  /* Verde alerta L1 */
  --exitol1: #D0FA6B;
  /* Verde alerta D1 */
  --exitod1: #9CBA4E;
  /* Verde alerta D2 */
  --exitod2: #3F4C22;

  /*Colores producto*/
  --auto: #00CEC2;
  --auto-l1: #DCF8FA;
  --auto-l2: #B8EFF2;
  --auto-l3: #97E6E2;
  --auto-l4: #5DD8D0;
  --auto-d1: #0A6C79;

  --moto: #7FD215;
  --moto-l1: #E1FFD2;
  --moto-l2: #C8FFAD;
  --moto-l3: #A7F083;
  --moto-extra-l25: #B8F898;
  --moto-extra-d05: #66A813;
  --moto-extra-d02: #71BE0F;
  --moto-d1: #498300;

  --casa: #FF9900;
  --casa-l1: #FFE2C6;
  --casa-l2: #FFD1A0;
  --casa-l3: #FFBC57;
  --casa-d1: #AE6002;

  --mascota: #CD63FF;
  --mascota-l05: #F5E2FF;
  --mascota-l1: #ECC6FF;
  --mascota-l2: #E5AEFF;
  --mascota-l3: #DC94FF;
  --mascota-d1: #7A20A5;

  --celular: #F154DE;
  --celular-l1: #FFEFFD;
  --celular-l2: #FFC3F7;
  --celular-l3: #FF9DF2;
  --celular-d1: #901777;

  --dental: #FF7121;
  --dental-l1: #FFE5D7;
  --dental-l2: #FFD3BC;
  --dental-l3: #FFA776;
  --dental-d1: #AC3E00;

  --vida: #9C69FF;
  --vida-l1: #E8DCFF;
  --vida-l2: #DAC8FF;
  --vida-l3: #B995FF;
  --vida-d1: #5529A0;

  --gmm: #F1B904;
  --gmm-l1: #FFF3DA;
  --gmm-l2: #FFE6B6;
  --gmm-l3: #F3D880;
  --gmm-d1: #A6761B;

  --viaje: #698AFF;
  --viaje-l1: #D1D8FF;
  --viaje-l2: #ADBBFF;
  --viaje-l3: #8DA2FF;
  --viaje-d1: #1C2D6A;


  --funerarios: #817EFF;
  --funerarios-l1: #DBDAFD;
  --funerarios-l2: #CAC8FD;
  --funerarios-l3: #8DA2FF;
  --funerarios-d1: #393794;

  --vision: #00C673;
  --vision-l1: #DAFFE9;
  --vision-l2: #B2FCD0;
  --vision-l3: #52E3A6;
  --vision-d1: #0A7C4C;

  --tipo-principal: 'DM Sans', sans-serif;
  /* Whatsapp color */
  --whatsapp: #25D366;
  --whatsappd1: #02AB41;


}

/* Modo oscuro */
@media (prefers-color-scheme: dark) {
  :root {
    --blanco: #fff;
  }
}

/* Reset padding y margin */
* {
  margin: 0;
  padding: 0;
  border: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: baseline;
}

/* Imagenes */
picture, video, iframe, figure {
  max-width: 100%;
  width: 100%;
  display: block;
  /* opcional */
  -o-object-fit: cover;
  object-fit: cover;
  /* opcional */
  -o-object-position: center center;
  object-position: center center;
}

/* Reseteamos los enlaces para funcionar como cajas... */
a {
  display: block;
  text-decoration: none;
  color: inherit;
  font-size: inherit;
}
a:hover {
  color: inherit;
  text-decoration: none;
}

/* ... excepto los que se encuentran en párrafos */
p a {
  display: inline;
}
ul{
  margin: 0;
  padding: 0;
}
/* Quitamos los puntos de los <li> */
li {
  list-style-type: none;
}

/* Configuramos anclas suaves */
html {
  scroll-behavior: smooth;
}

/* Desactivamos estilos por defecto de las principales etiquetas de texto */
h1, h2, h3, h4, h5, h6, p, span, a, strong, blockquote, i, b, u, em {

  font-weight: inherit;
  font-style: inherit;
  text-decoration: none;
  color: inherit;
  font-family: 'DM Sans', sans-serif !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

/* Evitamos problemas con los pseudoelementos de quotes */
blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

/* Configuramos el texto que seleccionamos */
::-moz-selection {
  background-color: var(--negro);
  color: var(--blanco);
}
::selection {
  background-color: var(--azul-primariol1);
  color: var(--azul-primariod1);
}

/* Nivelamos problemas de tipografías y colocación de formularios */
form, textarea, select, button, label {
  font-family: inherit;
  font-size: inherit;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  background-color: transparent;
  color: inherit;
  display: block;
  /* opcional */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input [type="radio"]{
  accent-color: var(--azul-primario) !important;
}
.custom-control-inline{
  display: flex !important;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff !important;
  border-color: var(--azul-primariod1) !important;
  background-color: var(--azul-primariol1) !important;
}
.modal-body.lateral hr{
  flex-grow: 1;
  height: .0625rem;
  background-color: var(--gris-claro2);
  margin: 0;
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check.active .form-check-label p{
  color: var(--dark);
  font-weight:600 ;
}

/* Reseteamos las tablas */
table, tr, td {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Evitamos problemas con los SVG */
svg {
  width: 100%;
  display: block;
  fill: currentColor;
}

/* (Probándolo, no usar en producción) En el caso de Agregar una  */
/* p svg{
  display: inline;
  width: initial;
} */
/* Configuramos la tipografía para toda la web */
body {
  min-height: 100vh;
  font-size: 100%;
  font-family: var(--tipo-principal);
  color: var(--negro);
  /* opcional */
  line-height: 1.4em;
  /* opcional */
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  /* opcional */
  font-smooth: always;
  /* opcional */
  -webkit-font-smoothing: antialiased;
  /* opcional */
  -moz-osx-font-smoothing: grayscale;
  overflow:auto;
}

h1, h2 {font-weight: 800 !important;}
h3 , h4{font-weight: 600 !important;}
h5, h6 {font-weight: 400 !important;}

h1{ font-size: 32px;}
h2{ font-size: 24px;}
h3{ font-size: 20px;}
h4{ font-size: 18px;}
h5{ font-size: 14px;}
h4 b, h5 b {font-weight: 600 !important;}
p {
  font-family: 'DM Sans', sans-serif !important;
  margin-top: 0;
  margin-bottom: 0;
}
button:focus {
  outline: none;
}

svg {
  width: inherit;
  display: block;
  fill: currentColor;
}
select{
  background-color: var(--exito);
}
label {
  margin-bottom:0!important;
  margin-left: 0;
  font-size: 14px;
  font-weight: 400;
  color: var(--gris);
}
label.active {
  margin-bottom:0!important;
  margin-left: 0;
  font-size: 14px;
  font-weight: 600;
  color: var(--azul-primario);
}
label a{
  margin-bottom: 4px !important;
  margin-left: 6.4px;
  font-size: 14px;
  font-weight: 600;
  color: var(--azul-primario);
  text-decoration: underline;

}
label a:hover{
  color: var(--azul-primariod1);
}
label b{
  margin-bottom: 0 !important;
  margin-left: 0;
  font-size: 14px;
  font-weight: 600;
  color: var(--gris80);
}
/* SCROLL SCROLL */
/* Personalización de la scrollbar en Chrome y Safari */

/* Estilo del 'pulggar' (la parte arrastrable de la barra de desplazamiento) */
::-webkit-scrollbar-thumb {
  background-color: var(--gris-claro2); /* r del pulgar */
  border-radius: .375rem; /* Radio de borde del pulgar */
  min-width: 3.125rem !important; /* Ancho mínimo del pulgar */
}
::-webkit-scrollbar-thumb:hover {
  background-color: var(--gris-claro3); /* Color del pulgar al pasar el cursor */
  transition: ease-in-out 2s;
}

::-webkit-scrollbar-track {
  background-color: var(--gris-claro); /* Color de la pista de la scrollbar */
}
::-webkit-scrollbar {
  width: .5rem; /* Ancho de la barra de desplazamiento */
  height: .5rem;
}



/* ESPACIOS *//* ESPACIOS *//* ESPACIOS *//* ESPACIOS */
.gap-0{
  gap: 0!important;
}
.gap-4{
  gap: 4px!important;
}

.gap-8{
  gap: 8px !important;
}
.gap-12{
  gap: 12px !important;
}
.gap-16{
  gap: 16px;
}
.gap-24{
  gap: 24px!important;
}
.gap-6{
  gap: 32px;
}
.gap-7{
  gap: 40px;
}
.gap-48{
  gap: 48px;
}
.white-space-nowrap{
  white-space: nowrap!important;
}
/* ALTURAS *//* ALTURAS *//* ALTURAS */
.h-in{
  height: inherit;
}
.h-10{
  height: 10%;
}
.h-20{
  height: 20% !important;
}
.h-30{
  height: 30%;
}
.h-40{
  height: 40%;
}
.h-55{
  height: 55%;
}
.h-50{
  height: 50%;
}
.h-60{
  height: 60%;
}
.h-70{
  height: 70%;
}
.h-75{
  height: 75%;
}
.h-80{
  height: 80% !important;
}
.h-85{
  height: 85%;
}
.h-88{
  height: 88%;
}
.h-90{
  height: 90%;
}
.h-100{
  height: 100%;
}
.mh-500{
  max-height: 500px;
}

/* WIDTH*//* WIDTH*//* WIDTH*/
.w-100{
  width: 100%;
}
.w-85{
  width: 85%!important;
}
.w-70{
  width: 70%!important;
}
.w-50{
  width: 50%!important;
}
.w-1{
  width: 16px;
}
.w-2{
  width: 24px;
}
.w-550{
  width: 550px;

}
.w-auto{
  width: auto !important;
}
/* MIN WIDTH */
.w-min-content{min-width: fit-content;
}
.w-min-content-12{min-width: 12rem;
}
.w-min-content-20{min-width: 20rem;
}
.w-min-content-25{min-width: 25rem;
}
.w-min-content-30{min-width: 30rem;
}
/* MIN WIDTH */

.w-max-150{max-width: 150px !important;
}
.w-max-350{max-width: 350px !important;
}
.w-max-550{max-width: 550px;
}

.w-in{
  width: inherit !important;
}

/* OVERFLOW */

.overflow-y{
  overflow-y: scroll;
}
/* ICONOS */
.icono-xsm{
  width: 10px !important;
  height: auto;
  object-fit: fill;

}
.icono-sm{
  width: 16px;
  object-fit: fill;
}
.icono-md{
  width: 24px;
  object-fit: fill;

}
.icono-btn{
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .25rem;
  border-radius: 32px;
  background-color: var(--gris-claro2);
}
.icono-btn:hover{

  background-color: var(--gris-claro3);
}

.icono-btn.sm{
  width: 1.5rem;
  height: 1.5rem;
}

.icono-btn.md{
  width: 2rem;
  height: 2rem;
}
.icono-btn.back{
  background-color: var(--gris-claro4);
}
.icono-btn.back:hover{
  background-color: var(--gris);
}
.icono-btn.exito svg{
  color: var(--gris-obscuro2);
}

.icono-btn.exito{
  background-color: var(--exitol1);
}
.icono-btn.exito:hover{
  background-color: var(--exito);
  transition: ease-in-out 1s;
}
.icono-btn.exito svg{
  color: var(--dark);
}


.icono-btn.yellow{
  background-color: var(--amarillo-alertal1);
}
.icono-btn.yellow:hover{
  background-color: var(--amarillo-alertad1);
  transition: ease-in-out 1s;
}
.icono-btn.yellow svg{
  color: var(--dark);
}

.icono-btn.red{
  background-color: var(--rojo-alertal1);
}
.icono-btn.red:hover{
  background-color: var(--rojo-alertad1);
  transition: ease-in-out 1s;
}


.icono-btn svg{
  width: 16px;
  color: var(--dark);
}
.icono-btn.gmm{
  background-color: var(--gmm);
}
.icono-btn.gmm:hover{
  background-color: var(--gmm);
}
.icono-btn.gmm svg{
  width: 22px;
  color: var(--gmm-d1);
}

.icono-btn.gmm-share{
  background-color: var(--gmm-l2);
}
.icono-btn.gmm-share:hover{
  background-color: var(--gmm-l3);
}
.icono-btn.gmm-share svg{
  width: 18px;
  color: var(--gmm);
}
.icono-btn.dental{
  background-color: var(--dental);
}
.icono-btn.dental:hover{
  background-color: var(--dental);
}
.icono-btn.dental svg{
  width: 22px;
  color: var(--dental-d1);
}

.icono-btn.dental-share{
  background-color: var(--dental-l2);
}
.icono-btn.dental-share:hover{
  background-color: var(--dental-l3);
}
.icono-btn.dental-share svg{
  width: 18px;
  color: var(--dental);
}

.icono-btn.vida{
  background-color: var(--vida);
}
.icono-btn.vida:hover{
  background-color: var(--vida);
}
.icono-btn.vida svg{
  width: 22px;
  color: var(--vida-d1);
}

.icono-btn.vida-share{
  background-color: var(--vida-l2);
}
.icono-btn.vida-share:hover{
  background-color: var(--vida-l3);
}
.icono-btn.vida-share svg{
  width: 18px;
  color: var(--vida);
}

.icono-btn.funerarios{
  background-color: var(--funerarios);
}
.icono-btn.funerarios:hover{
  background-color: var(--funerarios);
}
.icono-btn.funerarios svg{
  width: 22px;
  color: var(--funerarios-d1);
}

.icono-btn.funerarios-share{
  background-color: var(--funerarios-l2);
}
.icono-btn.funerarios-share:hover{
  background-color: var(--funerarios-l3);
}
.icono-btn.funerarios-share svg{
  width: 18px;
  color: var(--funerarios);
}


.icono-btn.vision{
  background-color: var(--vision);
}
.icono-btn.vision:hover{
  background-color: var(--vision);
}
.icono-btn.vision svg{
  width: 30px;
  color: var(--vision-d1);
}

.icono-btn.vision-share{
  background-color: var(--vision-l2);
}
.icono-btn.vision-share:hover{
  background-color: var(--vision-l3);
}
.icono-btn.vision-share svg{
  width: 18px;
  color: var(--vision);
}
.icono-btn.auto{
  background-color: var(--auto);
}
.icono-btn.auto:hover{
  background-color: var(--auto);
}
.icono-btn.auto svg{
  width: 26px;
  color: var(--auto-d1);
}

.icono-btn.auto-share{
  background-color: var(--auto-l2);
}
.icono-btn.auto-share:hover{
  background-color: var(--auto-l3);
}
.icono-btn.auto-share svg{
  width: 18px;
  color: var(--auto);
}

.icono-btn.danos{
  background-color: var(--azul-primariol1);
}
.icono-btn.danos:hover{
  background-color: var(--azul-primariol2);
}
.icono-btn.danos svg{
  width: 26px;
}

.icono-btn.danos-share{
  background-color: var(--azul-primariol1);
}
.icono-btn.danos-share:hover{
  background-color: var(--azul-primariol2);
}
.icono-btn.danos-share svg{
  width: 18px;
  color: var(--azul-primario);
}




.icono-btn.llamada{
  background-color: var(--azul);
}

.icono-btn.mail{
  background-color: var(--magenta);
}
.icono-btn.meet{
  background-color: var(--turquesa);
}
.icono-btn.meet-morado{
  background-color: var(--morado);
}
.icono-btn.whatsapp{
  background-color: var(--whatsapp);
}
.icono-btn-2{
  width: fit-content;
  padding: 8px;
  border-radius: 32px;
  background-color: var(--gris-obscuro1);
  /* border: .0625rem solid var(--azul-primario); */
  color: var(--gris-obscuro3);
}
.icono-btn-2.llamada{
  background-color: var(--azull3);
  color: var(--azuld1);
}
.icono-btn-2.mail{
  background-color: var(--magental3);
  color: var(--magentad1);
}
.icono-btn-2.meet-morado{
  background-color: var(--moradol3);
  color: var(--moradod1);
}
.icono-btn-2.meet{
  background-color: var(--turquesal3);
  color: var(--turquesad1);
}
.icono-btn-2.whatsapp{
  background-color: var(--whatsapp);
  color: var(--blanco);
}
.icono-btn-2 img{
  width: 12px;
}
.icono-btn-2:hover{
  background-color: var(--azul-primario2);
  color: var(--blanco);
}
.icono-btn-2.llamada:hover{
  background-color: var(--azul);

}
.icono-btn-2.mail:hover{
  background-color: var(--magenta);
}
.cta.mail:hover p{
  color: var(--magental4);
}
.icono-btn-2.meet:hover{
  background-color: var(--turquesa);
}
.icono-btn-2.meet-morado:hover{
  background-color: var(--morado);
}
.icono-btn-2.whatsapp:hover{
  background-color: var(--whatsappd1);
}
.cta{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cta p{
  font-size: .625rem;
  color: var(--gris-obscuro3);
}
.cta{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cta p{
  font-size: .625rem;
  color: var(--dark);
  font-weight: 700;
}
/* DISPLAYS *//* DISPLAYS *//* DISPLAYS */
.d-start{
  display: flex !important;
  align-items: center !important;
  justify-content: start !important;
}
.d-end{
  display: flex !important;
  align-items: center !important;
  justify-content: end !important;
}
.d-center{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.d-between{
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;

  flex-wrap: wrap;
  gap: .25rem;
}
.d-between-end{
  display: flex !important;
  align-items: end !important;
  justify-content: space-between !important;
}
.d-between-start{
  display: flex !important;
  align-items: start !important;
  justify-content: space-between !important;
}
.d-evenly{
  display: flex !important;
  align-items: center !important;
  justify-content: space-evenly !important;
  width: inherit !important;
}
.d-around{
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
  width: inherit !important;
}
.d-around-start{
  display: flex !important;
  align-items: start !important;
  justify-content: space-around !important;
  width: inherit !important;
}
.d-column-start{
  display: flex !important;
  flex-direction: column !important;
  align-items: start !important;
  justify-content: start !important;
}
.d-column-center{
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
}
.d-column-between{
  display: flex;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: space-between !important;
}
/* FLEX FLEX FLEX *//* FLEX FLEX FLEX */

.flex-grow-2{
  flex-grow: 2 !important;
}
.flex-grow-3{
  flex-grow: 3 !important;
}

.wrap-wrap{
  flex-wrap: wrap;
}
.no-wrap{
  flex-wrap: nowrap!important;
}

/* Backgrounds */ /* Backgrounds */ /* Backgrounds */
.bg-blanco{
  background-color: var(--blanco);
}
.bg-claro{
  background-color: var(--gris-claro);
}
.bg-primario{
  background-color: var(--azul-primariol1) !important;
}
.bg-turquesa{
  background-color: var(--turquesal1) !important;
}
.bg-morado{
  background-color: var(--moradol3) !important;
}
.bg-negociacion{
  background-color: var(--verde-negociacionl4) !important;
  color: var(--verde-negociaciond3);
}
.bg-grisclaro2{
  background-color: var(--gris-claro2)!important;
}
.bg-prymary{
  background-color: var(--azul-primario)!important;
}
.bg-danger{
  background-color: var(--rojo-alerta)!important;
}
/* Color */
.gris p{
  color: var(--gris80);
}
.gris70{
  color: var(--gris-obscuro3)!important;
}
/* COMPONENTES */ /* COMPONENTES */ /* COMPONENTES */ /* COMPONENTES */ /* COMPONENTES */ /* COMPONENTES */

/* BOTONES *//* BOTONES */
.btn{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 8px 12px;
  gap: 8px;
  font-size: .75rem;
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;
}
.btn svg{
  width: 16px;
}

.btn::selection{
  background-color: #00C8EF!important;
}

.btn.md{
  font-size: 16px;
}
.btn:focus{
  outline: none !important;
  box-shadow: none !important;
}
.btn.primario{
  background-color: var(--azul-primario);
  font-weight: 700;
  color: var(--blanco);
}
.btn.primario svg{
  color: var(--azul-primariod2);
}
.btn.primario2{
  background-color: var(--turquesa);
  font-weight: 700;
  color: var(--turquesad3);
}

.btn.lg{
  min-width:300px ;
}
.btn.primario:hover{
  background-color: var(--azul-primariod1);
}
.btn.primario2:hover{
  background-color: var(--turquesad1);
}
.btn.secundario{
  background-color: var(--azul-primariol1);
  color: var(--azul-primariod3);
  font-weight: 700;
}
.btn.secundario.lg{
  background-color: var(--moradol2);
  font-weight: 700;
  height: 2.5rem;
  width: 9rem;
  min-width: fit-content;
  padding-inline: 2rem;
}
.btn.secundario:hover{
  background-color: var(--azul-primariol2);
}
.btn.terciario{
  background-color: var(--blanco);
  border: var(--azul-primariol1) solid .125rem;
}

.btn.terciario:hover{
  background-color: var(--gris-claro2);
}
.btn.turquesa{
  background-color: var(--turquesa);
  font-weight: 700;
}
.btn.turquesa:hover{
  background-color: var(--turquesad1);
}
.btn.terciario.turquesa{
  background-color: var(--blanco);
  border: var(--turquesal1) solid .125rem;
}
.btn.terciario.turquesa:hover{
  background-color: var(--gris-claro2);
}

.btn.morado{
  background-color: var(--moradol1);
  font-weight: 700;
}
.btn.morado:hover{
  background-color: var(--morado);
}
.btn.terciario.morado{
  background-color: var(--blanco);
  border: var(--moradol1) solid .1875rem;
}
.btn.terciario.morado:hover{
  background-color: var(--gris-claro2);
}

.btn.negociacion{
  background-color: var(--verde-negociacion);
  font-weight: 700;
  text-decoration: none;
}
.btn.negociacion:hover{
  background-color: var(--verde-negociaciond1);
  text-decoration: none;
}

.btn.deshabilitado{
  background-color: var(--gris-claro2);
  font-weight: 700;
  text-decoration: none;
  color: var(--gris);
}
.btn.deshabilitado svg{
  color: var(--gris);
}

.btn.deshabilitado:hover{
  background-color: var(--gris-claro3);
  font-weight: 700;
  text-decoration: none;
  color: var(--gris);
}


.btn.alerta{
  background-color: var(--rojo-alerta);
  color: var(--blanco);
  font-weight: 700;
}

.btn.alerta:hover{
  background-color: var(--rojo-alertad1);
}
.btn.google{
  background-color: var(--blanco);
  border: var(--gris-obscuro1) solid .125rem;
}

.btn.google:hover{
  background-color: var(--gris-claro2);
}

.btn.show{
  border: none;
}
.tooltip {
  background-color: #02AB41;
}
.btn .badge {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;

}
/* ENLACES *//* ENLACES */
/* Sm */
.enlace{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  gap: 3.2px;
  color: var(--azul-primario);
  text-decoration: underline .0938rem;
  font-size: 14px;
  font-weight: 600;
  text-wrap: nowrap;
  cursor: pointer;
}


.enlace:hover{
  color: var(--azul-primariod2);
}
.enlace:active{
  background-color: var(--blanco);
  border: var(--blanco) solid .0063rem !important;
  color: var(--azul-primariod1);
}
.enlace:focus{
  background-color: var(--blanco);
  border: var(--blanco) solid .0063rem !important;
  color: var(--azul-primario1);
}
.enlace.gris{
  color: var(--gris) ;
}
.enlace.gris.principal:hover{
  color: var(--azul-primario) ;
}
.enlace.gris:hover{
  color: var(--gris-obscuro2) ;
}
.enlace.negro{
  color: var(--gris80) ;
}
.enlace.negro:hover{
  color: var(--dark) ;
}
.enlace.rosa{
  color: var(--magenta) ;
}
.enlace.rosa:hover{
  color: var(--magentad1) ;
}
.enlace.azul{
  color: var(--azul) ;
}
.enlace.azul:hover{
  color: var(--azuld1) ;
}
.enlace.morado{
  color: var(--morado) ;
}
.enlace.morado:hover{
  color: var(--moradod1) ;
}
.enlace.turquesa{
  color: var(--turquesa) ;
}
.enlace.turquesa:hover{
  color: var(--turquesad1) ;
}
/* Lg */
.enlace-lg h4{
  color: var(--azul-primario);

}
.enlace-lg h4:hover{
  color: var(--azul-primariod1);

}
.enlace-lg a{
  max-width: fit-content;
}
/* TAGS *//* TAGS */
.tag {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  min-width: fit-content;
  padding:  .25rem .5rem;
  width: auto;
  white-space: nowrap;
  margin-top: 0 !important;
  font-weight: 400;
  font-size: .75rem !important;
  margin-top: 0 !important;
  line-height: 0rem !important;
}
.tag.absolute{
  align-self: flex-end;
}
.tag div{
  padding: 0px 8px;
}
.tag p{
  font-weight: 400;
  font-size: .75rem !important;
  margin-top: 0 !important;
}
.tag.red{
  background-color: var(--rojo-alertal3);
}
.tag.red ,
.tag.red svg{
  color: var(--rojo-alertad2) !important;
}
.tag.yellow{
  background-color: var(--amarillo-alertal1);
}
.tag.yellow ,
.tag.yellow svg{
  color: var(--dark) !important;
}
.tag.green{
  background-color: var(--exitol1);
}
.tag.green {
  color: var(--dark) !important;
}
.tag.pago{
  background-color: var(--exitol1);
}
.tag.pago{
  color: var(--exitod2) !important;
}
.tag.cancelada{
  background-color: var(--rojo-alertal1);
}
.tag.cancelada{
  color: var(--dark);
}
.tag.turquesa{
  background-color: var(--turquesal2);
}
.tag.turquesa {
  color: var(--turquesad1) !important;
}
.tag.azul{
  background-color: var(--azul-primariol1);
}
.tag.azul ,
.tag.azul svg{
  color: var(--azul-primariod2) !important;
}
.tag.solicitud-general{
  background-color: var(--turquesal3);
}
.tag.solicitud-general ,
.tag.solicitud-general svg{
  color: var(--turquesad3) !important;
}

.tag.auto{
  background-color: var(--auto-l2);
}
.tag.auto ,
.tag.auto svg{
  color: var(--auto-d1) !important;
}
.tag.gmm{
  background-color: var(--gmm-l2);
}
.tag.gmm ,
.tag.gmm svg{
  color: var(--gmm-d1) !important;
}
.tag.danos{
  background-color: var(--vida-l2);
}
.tag.danos ,
.tag.danos svg{
  color: var(--vida-d1) !important;
}



.tag.gris{

}
.tag.gris {
  color: var(--gris) !important;
  font-weight: 600;
  padding:  .5rem .5rem;
  background-color: var(--gris-claro2);
  border-radius: 16px;
  width: max-content;
}


/* FORM *//* FORM *//* FORM *//* FORM *//* FORM *//* FORM *//* FORM *//* FORM *//* FORM */
.form-control,
.form-select{
  display: block;
  width: 100%;
  padding: 6px 8px;
  font-size: 12px;
  line-height: 1.5;
  color: var(--gris-obscuro3);
  background-color: var(--gris-claro);
  background-clip: padding-box;
  border: 1.5px solid var(--gris-claro2);
  border-radius:4px;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  min-width: 160px;
}
.form-control.ammiaAdmin-style::placeholder,
.form-select.ammiaAdmin-style::placeholder {
  font-size: 14px;
  line-height: 1.5;
  color: var(--gris);
}
.form-control.ammiaAdmin-style label,
.form-select.ammiaAdmin-style label {
  margin-bottom: 4px !important;
  margin-left: 9.6px !important;
  font-size: 14px;
  font-weight: 500;
}
.form-control.active,
.form-select.active{
  color: var(--dark)!important;
  background-color: var(--blanco)!important;
  border: 2px solid var(--azul-primario)!important;
  outline: 0!important;
  box-shadow: none!important;
}
.form-control:focus
.form-select:focus{
  color: var(--dark);
  background-color: var(--blanco);
  border: 2px solid var(--azul-primario);
  outline: 0;
  box-shadow: none;
}
.form-control.ammiaAdmin-style.turquesa:focus
.form-select.ammiaAdmin-style.turquesa:focus {
  color: #495057;
  background-color: var(--blanco);
  border-color: var(--azul-primario);
  outline: 0;
  box-shadow: 0 0 0 0px rgba(0, 200, 239, .25);
}
.form-control.ammiaAdmin-style option:hover
.form-select.ammiaAdmin-style option:hover {
  background-color: var(--gris-claro3) !important; /* Cambia el color de fondo en hover */
  color: var(--gris-obscuro3); /* Cambia el color del texto en hover */
  cursor: pointer; /* Cambia el cursor al pasar por encima */
}
.form-control, .form-select {
  font-size: 14px;
  line-height: 1.5;
  color: var(--gris);

}

.form-row{
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: nowrap;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: .75rem;
  gap: 32px;

}

.form-group{
  flex: 1 1 auto;


}


/* FORMS ITEMS *//* FORMS ITEMS */
.dropdown-item {
  display: block;
  width: auto;
  padding: 12px 8px;
  clear: both;
  font-weight: 400;
  color: var(--gris);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-size: 12px;
}
.dropdown-item p{
  font-weight: 400;
  color: var(--gris)!important;
  text-align: inherit!important;
  white-space: wrap;
  font-size: 12px;
  line-height: 1.2em ;
  margin-top: .75rem !important;
}
.dropdown-item:hover{
  background-color: var(--gris-claro);
  color: var(--gris);

}
.dropdown-item:focus{
  color: var(--dark);
  text-decoration:none;
  background-color: var(--gris-claro2);
}
.dropdown-item.active,
.dropdown-item:active{
  color:var(--dark);
  text-decoration:none;
  border: var(--azul-primario2) .0625rem solid;
  background-color:var(--azul-primario2)}

.dropdown-item.turquesa.active,
.dropdown-item.turquesa:active{
  color:var(--dark);
  text-decoration:none;
  border: var(--turquesal2) .0625rem solid;
  background-color:var(--turquesal2)}
.dropdown-item.morado.active,
.dropdown-item.morado:active{
  color:var(--dark);
  text-decoration:none;
  border: var(--moradol2) .0625rem solid;
  background-color:var(--moradol2)}


.dropdown-menu.notificaciones{
  max-height: 25rem;
  min-width: 25rem;
  width: auto;
  overflow-y: auto;
  border-radius: .5rem;
  padding: 1rem .5rem;
  border: none;
  box-shadow: 3px 3px 27px 0px rgba(88, 105, 131, 0.30);
}
.ventana-avatar{
  display: none;
  position: absolute;
  z-index: 12000;
  right: 36px;
  top: 60px;
  max-height: 45rem;
  min-width: 30rem;
  width: auto;
  overflow-y: auto;
  border-radius: .5rem;
  padding: 2rem .5rem;
  border: none;
  box-shadow: 3px 3px 27px 0px rgba(88, 105, 131, 0.30);
  background-color: var(--blanco);
  transition: all .3s ease-in-out;

}
.ventana-avatar.active{
  display: block;
  position: absolute;
  transition: all .3s ease-in-out;
}

.dropdown-menu.avatar p{
  color: var(--gris);
}

.dropdown-menu.busqueda{
  max-height: 25rem;
  min-width: 55rem;
  width: auto;
  overflow-y: auto;
  border-radius: .5rem;
  padding: 1rem .5rem;
  border: none;
  box-shadow: 3px 3px 27px 0px rgba(88, 105, 131, 0.30);

}

.dropdown-menu.busqueda a{
  color: var(--gris);
  padding: .5rem .25rem;
  width: 100%;
  font-size: 16px;
}
.dropdown-menu.busqueda a:hover{
  background-color: var(--gris-claro);
}
.dropdown-menu.chat{
  height: 25rem;
  min-width: 25rem;
  width: auto;
  border-radius: .5rem;
  padding: 1rem .5rem 0rem .5rem;
  border: none;
  box-shadow: 3px 3px 27px 0px rgba(88, 105, 131, 0.30);
}

.chat-modal{
  height: 25rem;
  min-width: 25rem;
  width: auto;
  border-radius: .5rem;
  padding: 1rem .5rem 0rem .5rem;
  border: none;
  box-shadow: 3px 3px 27px 0px rgba(88, 105, 131, 0.30);
}

.chat-contenedor{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap:  1rem;
  height: 75%;
  overflow-y: auto;
  width: 100%;
  padding: .5rem;
  border: solid var(--gris-claro2) 1px;
  border-radius: 8px;
}
.chat-mensaje{
  width: 15rem;
  display: flex;
  flex-direction: column;
  gap: .25rem;
}
.chat-mensaje h4,
.chat-mensaje h5{
  font-size: 12px;
  color: var(--gris-obscuro2);
  margin-bottom: .5rem !important;
}
.chat-mensaje p{
  font-size: 14px;
}
.chat-mensaje.derecha{
  align-self: start;
}
.chat-mensaje.izquierda{
  align-self: end;
}
.chat-mensaje-cliente{
  background-color: var(--azul-primario);
  padding: .5rem;
  border-radius: 8px;
}
.chat-mensaje-cliente h4{
  color: var(--gris-claro);
}
.chat-mensaje-cliente p{
  color: var(--blanco);
}
.chat-mensaje-ejecutivo{
  background-color: var(--gris-claro2);
  padding: .5rem;
  border-radius: 8px;
}

.chat-footer{
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  padding: .5rem;
}

@media (max-width: 768px){
  .dropdown-menu.notificaciones{

    min-width: 24rem;

  }
}


.no-shadow-modal .modal-content {
  box-shadow: none;
}


.btn.dropdown-toggle::after {
  display: none; /* Oculta la flecha del botón */
}
.dropdown.show .btn.dropdown-toggle {
  background-color: var(--blanco); /* Modifica el estilo del botón cuando está abierto */
}
.dropdown-item:active {
  outline: none!important; /* Desactivar el contorno predeterminado de enlace activo */
  box-shadow: none; /* Desactivar la sombra predeterminada de enlace activo en algunos navegadores */
}
.btn.show, .btn:first-child:active {
  color: none !important;
  background-color: none !important;
  border-color: var(--blanco);
}
.notificaciones-contenedor{
  border-left: var(--gris-claro3) solid 2px;
  margin: 0rem .65rem;
}
.notificaciones-contenedor2{
  border: var(--gris-claro2) solid 1px;
  margin: 0rem;
  padding: 1rem;
  border-radius: 8px;
  background-color: var(--blanco);
  height: 50%;
  overflow-y: scroll;
}
@media (max-width: 992px){
  .notificaciones-contenedor2{
    padding: .5rem;
  }
}
.eventos-contenedor{
  border: var(--gris-claro2) solid 1px;
  margin: 0rem;
  padding: 1rem;
  border-radius: 8px;
  background-color: var(--blanco);
  height: 100%;
  overflow: hidden;
  position: relative;
}
.eventos-contenedor .tab-content{
  overflow-y: scroll;
  height: 78%;
  padding: 1rem 0rem;
}

.eventos-contenedor .modal.especial{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-shadow-evento,
.modal-shadow-evento2,
.modal-shadow-evento3 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--dark); /* Ajusta el color y la opacidad según tus necesidades */
  opacity: 50%;
  display: none;
  z-index: 1050; /* Ajusta según sea necesario para estar encima del fondo del modal */
}
.modal-shadow-evento.visible,
.modal-shadow-evento2.visible,
.modal-shadow-evento3.visible {
  display: block;
  opacity: 50%;
}
.modal-open-no-backdrop{
  display: none;
}
.modal-backdrop.no-visible{
  opacity: 0%!important;
  display: none;
}
/* CHECK BOXES */
/* Ocultar el checkbox original */
input[type="checkbox"] {
  width: 1rem;
  border: solid 3px var(--exitod1)!important;
}

.checkbox-row{
  display: flex;
  gap: 1rem;

}
.checkbox-row label{
  color: var(--dark);
  font-size: 14px;
  font-weight: 600;
}

.checkbox-sm label,
.checkbox-sm a,
.checkbox-sm input{
  font-size: .875rem;
}

.search-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  width: 250px;
  gap: 3.2px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  position: relative;

}

@media (max-width: 1200px) {
  .search-container{
    width: 100%;
  }
}
.input-container {

  border-radius: 16px;
  padding: 0px 0px;
  gap: 3.2px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
}

.search-input {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  padding: 4.8px 16px;
  gap: 3.2px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  background-color: var(--blanco);
  border: .1875rem solid var(--gris-obscuro3);
  width: 12.5rem;

}

.search-icon {
  position: absolute;
  top: 50%;
  right: 32px;
  transform: translateY(-50%);
  font-size: 1.125rem;
  cursor: pointer;
  width: 16px !important;
}
/* GRUPO DE DATOS */
.info{
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  width: 100%;
}
.datos-grupo{
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  gap: 16px;

  width: 100%;
}
.info .datos-row{
  display: flex;
  justify-content: start;

}
.datos-row{
  display: flex;
  justify-content: start;
  gap: 20px;
  width: 100%;

}
.datos-row div{
  width:inherit ;
}
.datos-row,
.nota{
  width: 100%;
}

.nota li{
  font-size: .75rem;
  list-style:circle ;
}

.datos-row.notas{
  width: fit-content;
}
.datos-row .label,
.nota .label{
  font-size: 12px;
  color: var(--gris-obscuro3);
  font-weight: 600;
}

.datos-row .dato,
.nota .dato{
  font-size: 12px;
  color: var(--gris-obscuro2);
  line-height: normal;
}
.datos-row .dato.valor{
  font-size: 16px;
  color: var(--dark);
  line-height: normal;
  font-weight: 800;
}
.dato.hora{
  width: max-content;
}
.dato.gris{
  color: var(--gris80);
}
.dato.icono{
  display: flex;
  gap: 8px;
  min-width: fit-content;
}
.dato.icono svg{
  color: var(--gris-obscuro3);
}

.dato.icono svg:hover{
  color: var(--gris90);
}

/* SEPARADOR DE TARJETAS */
.separador-tarjetas{
  display: flex;
  align-items: center;
  padding: .5rem .25rem;
  gap: .5rem;
  margin: 2rem 0rem 1rem 0rem;
}
.separador-tarjetas h5{

  color: var(--dark);

}
.separador-tarjetas h4{
  text-wrap: nowrap;
  color: var(--gris);
  font-weight: 600!important;
  font-size: 16px;


}
.separador-tarjetas hr {
  flex-grow: 1;
  height: 1.5008px;
  background-color: var(--gris-claro4)!important;
  margin: 0;
  border-top: var(--gris-claro4) solid 2px;
}



/* MODALES MODALES MODALES *//* MODALES MODALES MODALES *//* MODALES MODALES MODALES */

/* GENERALES *//* GENERALES *//* GENERALES */
.modal-header,
.modal-body,
.modal-footer{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-header{
  border-bottom: solid var(--gris-claro3) 1px !important;
}


.modal-body{
  padding: 2rem 2rem;
  display: flex;
  align-items: start;
  justify-content: start;
}
.modal-body.poliza{
  padding: 1rem;
}
.modal-body h3{
  text-align: center;
}
.modal-footer {
  padding: 2rem 3rem;
  align-items: center;
  justify-content: space-around;
  gap: 3.5rem !important;
}
.modal-header .close {
  padding: 16px;
  margin: -16px -16px -16px auto;
  position: absolute;
  top: 1%;
  right: 3%;
  color: var(--azul-primario);
}
.modal-header.llamada .close {
  color: var(--azuld1);
}
.modal.modal-slide-right-in .modal-dialog {
  animation: slideInRight 0.3s forwards;
}
@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
.modal.modal-slide-right-out .modal-dialog {
  animation: slideOutRight 0.3s forwards;
}

@keyframes slideOutRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

/* LATERALES *//* LATERALES *//* LATERALES */
.modal.lateral {
  position: fixed;
  z-index: 1151;
  overflow: hidden;
  outline: 0;
  height: 102%;
  width: 100%;
  border-radius: 0px;
}
.modal-dialog.lateral{
  position: fixed;
  width: fit-content;
  margin: auto;
  max-width: none;
  height: 100%;
  right: 0;
}
.modal-content.lateral{
  border-radius: 0px;
  overflow: hidden;
  height: 100%;
}
.modal-header.lateral{
  height: 10%;
  background-color: var(--azul-primario2) ;
}
.modal-body.lateral{
  height: 60%;
  overflow: auto;
  align-items: start;
  min-width: 500px;
}
.modal-body.lateral form{
  width: auto;
}
.modal-footer.lateral{
  height: 15%;
  padding: 48px 48px;
  align-items: center;
  justify-content: center;
  border-top: var(--gris-obscuro1) solid 2px;
}

/* CONTAINER GENERAL */
.container-general {
  display: flex;
  justify-content: end;
  align-items: start;
}
.container-fluid.container-general{
  margin: 0 !important;
  padding: 0 !important;
  background-color: var(--gris-claro);
  width: 99%;
}
.container-dashboard{
  width: calc(100% - 6rem);
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 1rem 0rem;
  background-color: var(--gris-claro);
}
@media (max-width: 992px) {
  .container-dashboard{
    width: calc(100% - 5rem);

  }
}
@media (max-width: 767px) {
  .container-dashboard{
    width: inherit;

  }
}

/* HEADERS HEADERS HEADERS */
.header{
  background-color: var(--gris-claro);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px;
  height: 10%;
  border-radius: 8px;

}

.header-logo-notificaciones{
  display: flex;
  justify-content: space-between;
}

.header-barra-cta{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--gris-claro2);
  border: var(--gris-claro3) 1px solid;
  padding: 1rem;
  border-radius: 8px;
  gap: 3rem;
}


@media (max-width: 767px) {
  .header-barra-cta{
    flex-direction: column;
    gap: 1rem;
  }
}
.col-header{
  background-color: var(--blanco);
  border-bottom: solid .125rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 8px ;
  width: 100%;
}
.col-header p{
  font-size: 12px;
}

/* COLUMNAS */
.col-container{
  height: 100%;
  border-radius: 8px;
  border: var(--azul-primario2) solid .125rem;
  /* max-width: 35%; */
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden;
  background-color: var(--blanco)
}
.col-container .row{
  margin-left: 0;
  margin-right: 0;

}

/* NAV BAR *//* NAV BAR *//* NAV BAR *//* NAV BAR */
.navbar.ammiaAdmin {
  background-color: white;
  color: var(--gris-obscuro3);
  height: 100vh;
  transition: all .3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 2rem;
  position: fixed;
  z-index: 100;
  left: 0;
  width: 6rem;
  max-width: 6rem;
  padding: 0rem 0rem;
}


@media (max-width: 992px) {
  .navbar.ammiaAdmin{
    max-width: 5rem;
  }

}
.navbar-arrow{
  position: absolute;
  background-color: var(--blanco);
  padding: 8px;
  border: solid .0625rem var(--gris-claro3);
  border-radius: 80px;
  right: -1.125rem;
  top: 2.9375rem;
  transition: transform 0.3s ease;
}
.navbar-arrow:hover{
  background-color: var(--gris-claro);
  border: solid .0625rem var(--gris-claro3);
  cursor: pointer;
}

.navbar-arrow.rotable{
  transform: rotate(-180deg);
  transition: transform 0.3s ease;
}

.navbar.desplegado{
  width: 220px;
  transition: all .3s ease-in-out;
  z-index: 100;
  position: absolute;

}
.navbar .container-fluid{
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  margin: 0 !important;
  padding-inline: 0px;
  width: 100%;
  overflow: hidden;
}

.navbar-brand{
  max-width: 6rem;
  margin-right: 0 !important;
  padding: 4rem 0rem 1rem 0rem;
}
.navbar-brand img{
  max-width: 3.5rem;
  height: auto;

}


.navbar-texto{
  overflow: hidden;
  width: 0;
  transition: all 5s ease-in-out;

}

.navbar-nav{
  display: flex;
  align-items: center;
  flex-direction: column!important;
  gap: 1rem;

}
.navbar-nav svg{
  color: var(--gris-claro4);
}


.navbar-nav  .nav-item{
  padding: .5rem;
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.navbar-nav .nav-item:hover{
  background: var(--gris-claro2);
}
.navbar-nav .active{
  background: var(--azul-primario);
}
.navbar-nav .active svg{
  color: var(--dark);

}
.navbar-nav .active a{
  color: var(--blanco);;

}
.navbar-nav .active:hover{
  background-color: var(--azul-primariod1);
}
.navbar-collapse{
  flex-grow: 0;
}
.navbar-nav .nav-link {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  flex-wrap: wrap;
  color: var(--gris);
  font: 600 .75rem/normal DM Sans;
  width: auto;
  gap: 4px;
  transition: all .3s ease-in-out;
  padding: 0;
}


.nav-link p{
  text-align: left;
  overflow: hidden;
}
.nav-link svg{
  width: 20px;
  height: auto;
}


/* NAVBAR RESPONSIVE RESPONSIVE RESPONSIVE */
.navbar.responsive{
  padding: 1rem 1rem;
}
.navbar.responsive .container-fluid{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 !important;
  padding-inline: 0px;
  width: 100%;
  overflow: hidden;
}

.navbar-brand.responsive{
  max-width: 6rem;
  margin-right: 0 !important;
  padding: 0rem ;
}
.navbar-brand.responsive img{
  max-width: 3.5rem;
  height: auto;

}

@media (max-width: 768px) {
  .navbar-brand.responsive img{
    max-width: 7.5rem;
    height: auto;

  }
}
.navbar-texto{
  overflow: hidden;
  width: 0;
  transition: all 5s ease-in-out;

}

.navbar-nav.responsive{
  display: flex;
  align-items: center;
  flex-direction: column!important;
  gap: 0rem;
  margin-top: 2rem;
}
.navbar-nav.responsive svg{
  color: var(--gris-obscuro3);
}
.navbar-nav.responsive  .nav-item{
  padding: 0rem;
  width: 100%;
  height: auto;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.navbar-nav.responsive .nav-item:hover{
  background: var(--gris-claro2);
}
.navbar-nav.responsive .active{
  background: var(--azul-primario);
}
.navbar-nav.responsive .active svg{
  color: var(--dark);

}
.navbar-nav.responsive .active a{
  color: var(--blanco);

}
.navbar-nav.responsive .active:hover{
  background-color: var(--azul-primariod1);
}
.navbar-collapse{
  flex-grow: 0;
}
.navbar-nav.responsive .nav-link {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  flex-wrap: wrap;
  color: var(--gris-obscuro3);
  font: 600 .75rem/normal DM Sans;
  width: 100%;
  gap: 4px;
  transition: all .3s ease-in-out;
  padding: .5rem 0rem;
}


.nav-link p{
  text-align: left;
  overflow: hidden;
}
.nav-link svg{
  width: 20px;
  height: auto;
}

.navbar-nav.responsive .nav-link.active{
  color: var(--blanco);
}

.eventos-contenedor .nav-link {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  flex-wrap: wrap;
  color: var(--gris);
  background-color: var(--blanco);
  font: 600 .75rem/normal DM Sans;
  width: auto;
  gap: 4px;
  transition: all .1s ease-in-out;
  padding: .5rem;

}

.eventos-contenedor .nav-link.active {
  color: var(--dark);
  background-color: var(--gris-claro2);
}
.eventos-contenedor .nav-link.active:hover {
  color: var(--dark);
  background-color: var(--gris-claro3);
}
.eventos-contenedor .nav-link:hover {
  color: var(--dark);
  background-color: var(--gris-claro);
}
/* CAROUSEL CAROUSEL */
.carousel-buttons{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  max-width: 10rem ;
}
.carousel-control-next, .carousel-control-prev {
  position: inherit!important;
}
.carousel-control-prev-icon{
  background-image: url(../assets/img/ammia/iconos/prev-gray.svg)!important;
}
.carousel-control-next-icon{
  background-image: url(../assets/img/ammia/iconos/next-gray.svg)!important;
}
.carousel-control-next.absolute, .carousel-control-prev.absolute {
  position: absolute !important;
  top: 0%;
  height: 100px;

}





.owl-carousel{
  position: relative;

}
.owl-nav{

  position: absolute;
  top: 0;
  right: 0;
}
.owl-nav.visible{
  display: flex;
  position: absolute;
  top: 0;
  right: 0;

}

.owl-dots{
  display: none;
}
@media (max-width: 768px) {
  .owl-dots{
    display: block;
  }
  .carousel-buttons{
    display: none;
  }
}
.modal-content.evento{
  width: 80%;
  display: flex;
  flex-direction: column;
}


.carousel-indicators{
  bottom: -20!important ;
}

/* CONTAINER DATOS INDEX*//* CONTAINER DATOS INDEX*//* CONTAINER DATOS INDEX*/
.tittle h4{
  color: var(--dark);
}
.col-resumen-container{
  height: inherit;
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  max-width: 65%;
}
.col-resumen-container .row{
  margin: 0 !important;
}

.resumen.header{
  background-color: var(--blanco);
  border: var(--gris-obscuro2) solid 0.0625rem;
}
.resumen.header h5{
  color: var(--azul-primariod1);
}
/* Notificaciones *//* Notificaciones *//* Notificaciones */
.resumen-notificaciones{
  border-radius: 8px;
  overflow: hidden;
  height: 20%;
}
.resumen-notificaciones p{
  font-size: 12px;
  line-height: normal;
}
.resumen-notificaciones-header,
.resumen-notificacion{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--blanco);
}
.resumen-notificaciones-header{
  background-color: var(--gris-obscuro1);
  padding: 8px 24px;
  border-bottom: var(--gris-obscuro1) solid .0625rem;
  height: auto;

}
.resumen-notificaciones-scroll{
  overflow-y: scroll;
  height: 70%;
  min-height: 70%;
  width: 100%;
}
.resumen-notificacion{
  padding: 8px 8px;
  border-bottom: var(--gris-obscuro1) solid .0625rem;
}
.resumen-notificacion > div:nth-child(1) {
  max-width: 25%;
  min-width: 20%;
}
.resumen-notificacion > div:nth-child(2) {
  max-width: 60%;
  min-width: 60%;
  text-align: left;
}
.resumen-notificacion > div:nth-child(3) {
  max-width: 20%;
}
/* RESUMEN GENERAL RESUMEN GENERAL RESUMEN GENERAL RESUMEN GENERAL */
.h3-separador{
  color: var(--gris);
  white-space: nowrap;
}
.resumen-general{
  max-height: 520px;
  overflow: hidden;
}
@media (max-width: 992px){
  .resumen-general{
    max-height: 1200px;
    overflow: hidden;
  }
}
.resumen-general .col-4{
  padding: 0rem 0rem;
  max-height: 90% ;
}
/* Resumen programas *//* Resumen programas *//* Resumen programas */
.resumen-programas{
  border-radius: 8px;
  overflow: hidden;
}
.resumen-programas-header{
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1rem;
  padding: 8px 0px;
}

.resumen-programas-header h3{
  color: var(--gris);
  white-space: nowrap;
}
.resumen-programas-header hr{
  width: 100%;
  border: var(--gris-claro4) 1px solid;
}
.resumen-programas-contenedor{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0px 16px 16px ;
  gap: 16px;
  overflow-x: scroll;
  width: 100%;
  border: var(--gris-claro2) solid 1px;
  border-radius: 1rem;
}
.resumen-programas-card{
  background-color: var(--blanco);
  border: var(--gris-claro2) solid 1px;
  padding: 16px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: fit-content;
}
.resumen-programas-card h4{
  color: var(--gris-obscuro1);
  white-space: nowrap;
}

/* Resumen polizas *//* Resumen polizas *//* Resumen polizas */
.resumen-polizas{
  border-radius: 8px;
  overflow: hidden;
}
.resumen-polizas-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 8px 0px;
  width: 100%;
}

.resumen-polizas-header h3,
.resumen-beneficios h3{
  color: var(--gris);
  white-space: nowrap;
}.resumen-beneficios-header{
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   margin-bottom: 2rem;
 }
.resumen-polizas-header hr,
.resumen-beneficios-header hr{
  width: 100%;
  border: var(--gris-claro4)1px solid;
}
@media (max-width: 992px){
  .resumen-beneficios-header{
    flex-direction: column;
    justify-content: center;
  }
}

.resumen-polizas-card{
  background-color: var(--blanco);
  border: var(--gris-claro2) solid 1px;
  padding: 16px 16px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: fit-content;
  margin: 0rem 1rem;
  min-width: 20rem;
}

@media (max-width: 768px) {
  .resumen-polizas-card{
    margin: 0rem 0rem;
  }
}
.resumen-polizas-card h3{
  white-space: nowrap;
  font-size: 16px;
}
.resumen-polizas-card h4{
  font-size: 14px;
  font-weight: 600!important;
  color: var(--gris-obscuro1);
  white-space: nowrap;
}
.resumen-polizas-card h5{
  font-size: 12px;
  font-weight: 400!important;
  color: var(--gris);
  white-space: nowrap;
}


.resumen-polizas-card-detalle{
  background-color: var(--gris-claro);
  border: var(--gris-claro2) solid 1px;
  padding: 1rem 2rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .75rem;
  width: auto;
  margin: auto;
  min-width: 20rem;
  max-width: 25rem;
}
.resumen-polizas-card-detalle hr{
  flex-grow: 1;
  height: 1.5008px;
  background-color: var(--gris-claro4)!important;
  margin: 0;
  border-top: var(--gris-claro4) solid 2px;
  width: 100%;
}
.resumen-polizas-card-detalle h3{
  font-size: 18px;
}
.resumen-polizas-card-detalle h4{
  font-size: 14px;
  color: var(--gris);
}

.resumen-polizas-card-detalle h5{
  font-size: 12px;
  color: var(--gris);
}

.resumen-polizas-card-detalle p{
  font-size: 12px;
  color: var(--gris);
}

.resumen-polizas-card-detalle.gmm .label{
  color: var(--gmm-d1);
}
.resumen-polizas-card-detalle.gmm .dato{
  color: var(--dark);
  font-size: 14px;
}
.resumen-polizas-card-detalle.gmm .dato b{
  font-weight: 600;
}

.resumen-polizas-card-detalle.dental .label{
  color: var(--dental-d1);
}
.resumen-polizas-card-detalle.dental .dato{
  color: var(--dark);
  font-size: 14px;
}
.resumen-polizas-card-detalle.dental .dato b{
  font-weight: 600;
}
.resumen-polizas-card-detalle.auto .label{
  color: var(--auto-d1);
}
.resumen-polizas-card-detalle.auto .dato{
  color: var(--dark);
  font-size: 14px;
}
.resumen-polizas-card-detalle.auto .dato b{
  font-weight: 600;
}

.resumen-polizas-card-detalle.transportes .label{
  color: var(--azul-primariod1);
}
.resumen-polizas-card-detalle.transportes .dato{
  color: var(--dark);
  font-size: 14px;
}
.resumen-polizas-card-detalle.transportes .dato b{
  font-weight: 600;
}

.anexos-descargables-contenedor{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  height:70%;
  overflow-y: scroll;
}
.anexos-descargables-contenedor.beneficios{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  max-height: 15rem;
  overflow-y: scroll;
}
.modal-header.evento{
  align-items: start;
  flex-direction: column;
  gap: .75rem;
}
.modal-header.evento h3{
  color: var(--dark)!important;
}

.modal-content.evento .modal-body{
  padding: 1rem!important;
}
.siniestro-anexo{
  width: 100%;
  padding: 1rem;
}
.siniestro-anexo h4{
  font-weight: 600 !important;
}
.anexo-card{
  display: flex;
  gap: 1rem;
  background-color: var(--gris-claro);
  border-radius: 8px;
  border: var(--gris-claro3) solid 1px;
  padding: 1rem;
  justify-content: space-around;
}
.anexo-card p{
  font-size: 12px;
  color: var(--gris);

}
/* RESUMEN DE BENEFICIOS  RESUMEN DE BENEFICIOS  RESUMEN DE BENEFICIOS */
.resumen-beneficios{
  max-height: 650px;
  overflow: hidden;
  padding: 1rem 0rem;
}
.resumen-analiticas{
  max-height: 28rem;
  display: flex;
  gap: 16px;
}


.polizas-maestras-contenedor{
  background-color: var(--blanco);
  border-left: 1px var(--gris-claro2) solid;
  border-right: 1px var(--gris-claro2) solid;
  border-bottom: 1px var(--gris-claro2) solid;
  border-top: 1px var(--blanco) solid;
  border-radius: 8px;
  padding: 1rem;
  margin-top: 0rem;

}
.resumen-polizas-contenedor{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0px 16px 16px ;
  gap: 16px;
  width: 100%;
  border: var(--gris-claro2) solid 1px;
  border-radius: 1rem;
}

.asegurados-beneficios-contenedor{
  background-color: var(--blanco);
  border: 1px var(--gris-claro2) solid;
  border-radius: 8px;
  padding: 1rem;
  max-height: 38rem ;
  margin-top: 0rem;

}

.asegurados-beneficios-card{

}
.table-container {
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
}

.table-fixed-header thead {
  position: sticky;
  top: -1rem;
  z-index: 1;
  background-color: var(--blanco);

}
.table-fixed-header thead h4{
  font-size: 14px !important;
  white-space:  nowrap;

}
.table-fixed-header thead tr{
  border-bottom: var(--gris) solid 1px !important;
  height: 4rem;
}

.table tbody{
  height: 100px;
  overflow-y: auto;
}
.table th {
  background-color: var(--azul-primario3);
  min-width: auto !important;
  vertical-align: middle;
}

.table td {
  background-color: var(--blanco);
  min-width: auto !important;
  vertical-align: middle;
  font-size: 14px;
  padding:1.25rem .25rem;

}

.table tbody tr.active {
  cursor: pointer;
  border: var(--gris-claro2) solid 1px;
  background-color: var(--gris-claro);
}

.table tbody tr td {
  background-color: rgba(255, 255, 255, 0);

}

.table tbody tr:hover {
  cursor: pointer;
  border: var(--gris-claro2) solid 1px;
  background-color: var(--gris-claro);

}





.table tbody tr.selected td{
  background-color: var(--gris-claro4);
  border: .1875rem solid var(--gris);
}

.table p{
  font-size: 12px
}

.asegurados-beneficios-card h5{
  font-size: 14px;
  color: var(--dark);
  font-weight: 600!important;
}
.asegurados-beneficios-card h6{
  font-size: 12px;
  color: var(--gris);
  font-weight: 400;
  white-space: normal;
  width: 160px;
}




.carousel-seguros-contenedor{
  display: flex;
  align-items:start ;
  height: 440px;
  overflow-y: scroll;
  padding: 2rem 0rem;
}
.carousel-seguros-contenedor.beneficios{
  display: flex;
  align-items:start;
  height: inherit;
  overflow-y: auto;
  padding: 2rem 0rem;
  width: 100%;
  position: relative;
}



.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: var(--azul-primario);
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity .6s ease;
}
.carousel-indicators .active {
  opacity: 1;
}
.resumen-polizas-contenedor.beneficios{

  padding: 1rem;
  border: var(--gris-claro2) solid 0px;
  border-radius: 8px;
}

.estadisticas-solicitudes-contenedor{
  max-height: 32rem;
  display: flex;
  gap: 16px;
}
.eventos-siniestros-col{
  max-height: 100% ;
  margin-top: 0rem;

}
.eventos-siniestros-col.beneficios{
  padding: 0rem 0rem;
  max-height: 100% ;
  margin-top: 0rem;

}
@media (max-width: 992px) {
  .resumen-beneficios{
    max-height: inherit;

  }
  .eventos-siniestros-col{
    margin-top: 2rem;
    height: 600px!important;
    max-height: 40% ;
  }
}
.solicitudes-contenedor{
  overflow-y: scroll;
  height: 72%;
  padding: 0 .5rem;
}
.siniestros-contenedor{
  overflow-y: scroll;
  height: 70%;
  padding: 0 .5rem;
}
.siniestros-contenedor2{
  overflow-y: scroll;
  height:400px;
  padding: 1rem 2rem;
  width: 100%;
}
.nav-pills.nueva-solicitud.cobranza{
  background-color: var(--blanco);
  border-radius: 8px;
  padding: 1rem .5rem;
}
.cobranzas-contenedor-padre{
  border: var(--gris-claro2) solid 1px;
  margin: 0rem;
  padding: 1rem;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}
.cobranzas-contenedor{
  height: 700px;
  background-color: var(--blanco);
  overflow: hidden;
  padding: 1rem .25rem;
  border-radius: 0px 8px 8px 8px;
  border-left: var(--gris-claro2) solid 1px;
  border-right: var(--gris-claro2) solid 1px;
  border-bottom: var(--gris-claro2) solid 1px;
  border-top: var(--blanco) solid 1px;
}
.table-container-cobranzas {
  height: 80%;
  overflow-y: auto;
  overflow-x: auto;
  background-color: var(--blanco);


}
.polizas-vehiculos-contenedor{
  height: 850px;
  background-color: var(--blanco);
  overflow: hidden;
  padding: 1rem .25rem;
  border-radius: 0px 8px 8px 8px;
  border-left: var(--gris-claro2) solid 1px;
  border-right: var(--gris-claro2) solid 1px;
  border-bottom: var(--gris-claro2) solid 1px;
  border-top: var(--blanco) solid 1px;
}
.polizas-vehiculos-tabla {
  height: 50%;
  overflow-y: auto;
  overflow-x: auto;
  background-color: var(--blanco);


}
.polizas-vehiculos-tabla.altura{
  height: 500px;
  overflow-y: auto;
  overflow-x: auto;
  background-color: var(--blanco);


}
.cobranzas-card-label{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 4.8px;
}
.cobranzas-card{
  display: flex;
  background-color: var(--blanco);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0rem .5rem 1.25rem .125rem #E3E3E3;
}
.cobranzas-card h4{
  color: var(--dark);
  font-weight: 600 !important;
}

.cobranzas-card p{
  font-weight: 400!important;
  color: var(--gris)!important;
  text-align: inherit!important;
  white-space: wrap;
  font-size: 12px!important;
  line-height: 1.2em !important;
}
.cobranzas-card.alta .cobranzas-card-label{
  background-color: var(--azul-primariol1);
}
.cobranzas-card.general .cobranzas-card-label{
  background-color: var(--turquesal3);
}
.cobranzas-card.baja .cobranzas-card-label{
  background-color: var(--rojo-alertal3);
}
.cobranzas-card.siniestro .cobranzas-card-label{
  background-color: var(--dental-l2);
}

.cobranzas-card-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 8px;
  padding: 12px 8px 12px 8px;
  width: 100%;
}

.nav-tabs {
  border-bottom: var(--gris-claro2) solid 1px !important;
}

.nav-tabs .nav-link {
  border: var(--gris-claro2) solid 1px;
  background-color: var(--gris-claro);
  color: var(--gris-claro4);
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  padding: 1rem 2.5rem;
}
.nav-tabs .nav-link:hover{
  border: var(--gris-claro2) solid 1px;
  color: var(--dark);
  background-color: var(--gris-claro2);
  border-color: var(--blanco);
}


.nav-tabs .nav-link.active {
  color: var(--dark);
  background-color: var(--blanco);
  border-left: var(--gris-claro2) solid 1px;
  border-right: var(--gris-claro2) solid 1px;
  border-bottom: var(--blanco) solid 1px;
  border-top: var(--gris-claro2) solid 1px;
  font-weight: 600;
}




.resumen-cobranzas-card{
  background-color: var(--gris-claro);
  border: var(--gris-claro2) solid 1px;
  padding: 1rem 2rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .75rem;
  width: auto;
  margin: 0rem 1rem;
  min-width: 24rem;
}
.resumen-cobranzas-card hr{
  flex-grow: 1;
  height: 1.5008px;
  background-color: var(--gris-claro4)!important;
  margin: 0;
  border-top: var(--gris-claro4) solid 2px;
  width: 100%;
}
.resumen-cobranzas-card h3{
  font-size: 16px!important;
}
.resumen-cobranzas-card h4{
  font-size: 14px;
  color: var(--gris);
}

.resumen-cobranzas-card h5{
  font-size: 12px;
  color: var(--gris);
}

.resumen-cobranzas-card p{
  font-size: 12px;
  color: var(--gris);
}


.resumen-cobranzas-card .label{
  color: var(--gris)!important;
}
.resumen-cobranzas-card .dato{
  color: var(--dark)!important;
}

.resumen-cobranzas-card .dato-cifra{
  color: var(--dark)!important;
  font-size: 18px;
  font-weight: 600;
}



.resumen-cobranzas-card.gmm .label{
  color: var(--gmm-d1);
}
.resumen-cobranzas-card.gmm .dato{
  color: var(--dark);
  font-size: 14px;
}
.resumen-cobranzas-card.gmm .dato b{
  font-weight: 600;
}

.resumen-cobranzas-card.dental .label{
  color: var(--dental-d1);
}
.resumen-cobranzas-card.dental .dato{
  color: var(--dark);
  font-size: 14px;
}
.resumen-cobranzas-card.dental .dato b{
  font-weight: 600;
}
.resumen-cobranzas-card.auto .label{
  color: var(--auto-d1);
}
.resumen-cobranzas-card.auto .dato{
  color: var(--dark);
  font-size: 14px;
}
.resumen-cobranzas-card.auto .dato b{
  font-weight: 600;
}

.resumen-cobranzas-card.transportes .label{
  color: var(--azul-primariod1);
}
.resumen-cobranzas-card.transportes .dato{
  color: var(--dark);
  font-size: 14px;
}
.resumen-cobranzas-card.transportes .dato b{
  font-weight: 600;
}



/* ESTADISTICAS ESTADISTICAS ESTADISTICAS */
.estadisticas-contenedor{
  background-color: var(--blanco);
  border: 1px var(--gris-claro2) solid;
  border-radius: 8px;
  padding: 1rem;

}
.estadisticas-contenedor h4{
  color: var(--dark);
}

.estadisticas-contenedor h3,
.eventos-contenedor h3{
  color: var(--dark);
  font-size: 18px;
  margin-bottom: 0rem
}

.estadisticas-contenedor.beneficios{
  background-color: var(--blanco);
  border: 1px var(--gris-claro2) solid;
  border-radius: 8px;
  padding: 1rem;
  max-height: 28rem ;
  margin-top: 0rem;

}


.carousel-item-poliza-maestra{
  height: 24rem;
}
.estadisticas-card-label{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 4.8px;
}
.estadisticas-card{
  display: flex;
  background-color: var(--blanco);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0rem .5rem 1.25rem .125rem #E3E3E3;
}
.estadisticas-card h4{
  color: var(--dark);
  font-weight: 600 !important;
}

.estadisticas-card p{
  font-weight: 400!important;
  color: var(--gris)!important;
  text-align: inherit!important;
  white-space: wrap;
  font-size: 12px!important;
  line-height: 1.2em !important;
}
.estadisticas-card.alta .estadisticas-card-label{
  background-color: var(--azul-primariol1);
}
.estadisticas-card.general .estadisticas-card-label{
  background-color: var(--turquesal3);
}
.estadisticas-card.baja .estadisticas-card-label{
  background-color: var(--rojo-alertal3);
}
.estadisticas-card.siniestro .estadisticas-card-label{
  background-color: var(--dental-l2);
}

.estadisticas-card-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 8px;
  padding: 12px 8px 12px 8px;
  width: 100%;
}

/* NUEVA SOLICITUD NUEVA SOLICITUD NUEVA SOLICITUD */
.nueva-solicitud-check{
  background-color: var(--gris-claro);
  border: var(--gris-claro3) 1px solid;
  border-radius: 8px;
  padding: .5rem;
  width: 10rem;
  height: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  transition: ease-in-out .2s;
}
.nueva-solicitud-check svg{
  color: var(--gris);
  height: 24px;
}

.nueva-solicitud-check p{
  color: var(--gris);
  text-align: center;
  font-size: 14px;
  font-weight: 600;

}

.nueva-solicitud-check.active{
  background-color: var(--azul-primariol1);
  border: var(--azul-primariol2) solid 1px;
  transition: ease-in-out .2s;
}

.nueva-solicitud-check.active p,
.nueva-solicitud-check.active svg{
  color: var(--dark);
  font-weight: 600;
}
/* SELECCIONA ALTA BAJA SELECCIONA ALTA BAJA SELECCIONA ALTA BAJA */
.seleccionasteAlta,
.seleccionasteBaja,
.form-beneficiario,
.form-beneficiario-container{
  flex-direction: column;
}

/* POLIZA MAESTRA POLIZA MAESTRA POLIZA MAESTRA */
.poliza-maestra{
  background-color: var(--blanco);
  border: var(--gris-claro3) 1px solid;
  border-radius: 8px;
  padding: .5rem;
  width: 14rem;
  height: 12rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  transition: ease-in-out .2s;
}
.poliza-maestra h4{
  color: var(--gris);
  text-align: center;
  font-size: 16px;
  font-weight: 400!important;

}

.poliza-maestra h5{
  color: var(--gris-claro2);
  text-align: center;
  font-size: 14px;
  font-weight: 600 !important;

}


.poliza-maestra.active{
  background-color: var(--gris-claro);
  border: var(--azul-primariol1) solid 1px;
  transition: ease-in-out .2s;
}

.poliza-maestra.active h4{
  color: var(--dark);
  font-weight: 600!important;
}
.poliza-maestra.active h5{
  color: var(--gris);
}

/* RECONOCIMIENTO ANTIGUEDAD */

.reconocimiento-antiguedad{
  background-color: var(--gris-claro);
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 1rem .5rem;
  border: 1px solid var(--gris-claro2);
  border-radius: 8px;
  margin: 1.5rem 0rem;
}
.reconocimiento-antiguedad h5{
  font-size: 14px;
  color: var(--dark);
  font-weight: 600!important;
}
.reconocimiento-antiguedad p{
  font-size: 12px;
  color: var(--gris);
  font-weight: 400!important;
}

@media (max-width: 992px) {
  .reconocimiento-antiguedad{
    flex-direction: column;
    gap: 2rem;
  }}
.reconocimiento-antiguedad.detalle{
  background-color: var(--gris-claro);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border: 1px solid var(--gris-claro2);
  border-radius: 8px;
  margin: 0rem 0rem;
  width: 90%;
}


/* BENEFICIOS BENEFICIOS BENEFICIOS BENEFICIOS */

.beneficios-contenedor{
  max-height:fit-content;
  overflow: hidden;
  padding: 1rem 0rem;
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.beneficios-contenedor-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0rem;
}
.resumen-polizas-header h3{
  color: var(--gris);
  white-space: nowrap;
}
.resumen-polizas-header hr,
.beneficios-contenedor-header hr,
.asegurados-beneficios-contenedor hr{
  width: 100%;
  border: var(--gris-claro4)1px solid;
}


@media (min-width: 1200px){
  .modal-xl.xxl{
    min-width: 1262px !important;
  }
}



/* Resumen ventas *//* Resumen ventas *//* Resumen ventas */
.resumen-ventas{
  border: var(--gris-obscuro2) solid .0625rem;
  border-radius: 8px;
  overflow: hidden;
  height: 40%;
}
.resumen-ventas-header{
  background-color: var(--blanco);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;

}
.resumen-ventas-datos{
  height: 80%;
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 16px;
  gap: 16px;
  overflow-y: scroll;
}
.resumen-ventas-datos .row{
  width: 100%;
}
.resumen-ventas-card{
  background-color: var(--blanco);
  padding: 16px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 200px;
  max-width: fit-content;
}
.resumen-ventas-card h3{
  font-size: 16px;
  color: var(--gris-obscuro3);
}
.resumen-ventas-card-header h2{
  color: var(--gris-obscuro2) !important;
  font-weight: 400 !important;
}
.resumen-ventas-card-header h2 b{
  color: var(--dark) !important;
  font-weight: 800 !important;
}
.resumen-ventas-card-content p{
  font-size: 12px;
  color: var(--gris-obscuro3);
  line-height: normal;
  max-width: 192px;
}
.resumen-ventas-card-content b{
  font-weight: 800;
  color: var(--dark);
}
.resumen-ventas-card-content{
  border-top: .0625rem solid var(--gris-obscuro1);
  padding-top: 8px;
}
/* Funel de resumen */
.funel-resumen-container{
  border: var(--verde-negociacionl1) solid .1875rem;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  height: inherit;
}
.funel-resumen-header{
  background-color: var(--verde-negociacionl1);
  padding: 16px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.col-funel-filtros{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px ;
  width: 100%;
}
.funel-resumen-col{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 8px;
}
.funel-resumen-col-scroll{
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: rgba(243, 11, 11, 0.918) transparent !important;
  padding: 16px 4.8px 16px 0px;
  background-color: var(--blanco);
}
.funel-resumen-col .header,
.funel-resumen-col .footer{
  border-radius: 8px;
  padding: 16px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.funel-resumen-col .header.etapa1,
.funel-resumen-col .footer.etapa1{
  background-color: var(--verde-negociacionl4);
}
.funel-resumen-col .header.etapa2,
.funel-resumen-col .footer.etapa2{
  background-color: var(--verde-negociacionl2);
}
.funel-resumen-col .header.etapa3,
.funel-resumen-col .footer.etapa3{
  background-color: var(--verde-negociacionl1);
}
.funel-resumen-col .header.etapa4,
.funel-resumen-col .footer.etapa4{
  background-color: var(--verde-negociacion);
}

/* Cards */
.card-resumen{
  border-radius: 16px;
  margin-bottom: 16px;
  overflow: hidden;
}
.card-resumen h5{
  font-size: 14px;
  font-weight: 700 !important;
}
.card-resumen .header{
  background-color: var(--gris-obscuro1);
  padding: 0px 0px 4.8px 3.2px;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 4.8px;
}

.card-resumen .content{
  padding: 0px 0px 4.8px 6.4px;
}

.card-resumen.empresa{
  border-top: .375rem solid var(--moradol3);
  border-bottom: .1875rem solid var(--moradol3);
  border-right: .1875rem solid var(--moradol3);
  border-left: .1875rem solid var(--moradol3);
}
.card-resumen.individual{
  border-top: .375rem solid var(--turquesal2);
  border-bottom: .1875rem solid var(--turquesal2);
  border-right: .1875rem solid var(--turquesal2);
  border-left: .1875rem solid var(--turquesal2);
}
.card-resumen.empresa .header:hover{
  background-color: var(--moradol3);
}
.card-resumen.individual .header:hover{
  background-color: var(--turquesal2);
}

.modal-slide-right .modal-dialog {
  animation: slideRightIn 0.5s ease;
}

.modal-slide-right .modal.fade .modal-dialog.modal-dialog.slideRightOut {
  animation: slideRightOut 0.5s ease;
}

@keyframes slideRightIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideRightOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}


option:hover{
  background-color: var(--gris-claro3) !important; /* Cambia el color de fondo en hover */
  color: var(--gris-obscuro3); /* Cambia el color del texto en hover */
  cursor: pointer; /* Cambia el cursor al pasar por encima */
}

/* COLUMNA DE TAREAS *//* COLUMNA DE TAREAS *//* COLUMNA DE TAREAS *//* COLUMNA DE TAREAS */
.col-container.tareas{
  height: 100%;
  border-radius: 8px;
  border: var(--azul-primario2) solid .125rem;
  max-width: 35%;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden;
  background-color: var(--blanco)
}
.col-container.tareas .row{
  margin-left: 0;
  margin-right: 0;

}
.col-header.tareas{
  background-color: var(--azul-primario3);
  border-bottom: var(--azul-primario2) solid .125rem;

}
.cards-container{
  padding: 8px 4px;
  height: 90%;
}
.col-tareas-filtros{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px ;
}
.tareas-cta{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 24px;
  gap: 8px;

  width: 100%;

}
.col-tareas-cards {
  height: 80%;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: rgba(243, 11, 11, 0.918) transparent !important;
  padding: 16px 4px;
  background-color: var(--gris-claro);
}

.collapse,
.collapsing{
  width: 100%;

}

/* Fichas de tareas *//* Fichas de tareas *//* Fichas de tareas */

.tareas-card-label{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 4.8px;
}
.tareas-card{
  display: flex;
  background-color: var(--blanco);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0rem .5rem 1.25rem .125rem #E3E3E3;
}

.desplegable svg{
  color: var(--gris-obscuro2);
}
.desplegable svg:hover{
  color: var(--gris80);
}
.card-tarea,
.card-tarea .info,
.card-tarea .iconos-editar-borrar,
.card-tarea .acciones-tarea,
.tarea-completada{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.card-tarea {
  flex-direction: column;
  align-items: start;
  gap: 8px;
  padding: 12px 8px 12px 8px;
  width: 100%;
}
.card-tarea .header,
.card-tarea .acciones-tarea{
  width: -webkit-fill-available;
}
.card-tarea .tittle{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.card-tarea h4{
  font-size: .875rem;
}

.acciones-tarea{
  margin-top: 16px;
}

.card-tarea .seguro-empresa{
  background-color: var(--gris-claro);
  padding: 1rem;
  border-radius: .25rem;
  border: solid 2px var(--gris-claro3);
}
.card-tarea .seguro-empresa.white{
  background-color: var(--blanco);
  padding: 1rem;
  border-radius: .25rem;
  border: solid 2px var(--gris-claro3);
}
.card-tarea .seguro-empresa.perdido{
  background-color: var(--rojo-alertal3);
  padding: 1rem;
  border-radius: .25rem;
  border: solid 2px var(--rojo-alertal1);
}
/* Fichas de notificacion *//* Fichas de notificacion *//* Fichas de notificacion */
/* Fichas de notificacion *//* Fichas de notificacion *//* Fichas de notificacion */

.notificaciones-card-label{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 4.8px;
}
.notificaciones-card{
  display: flex;
  background-color: var(--blanco);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0rem .5rem 1.25rem .125rem #E3E3E3;
}
.notificaciones-card-label img{
  width: 1rem;
}

.llamada .notificaciones-card-label{
  background-color: var(--azull2);
}

.mail .notificaciones-card-label{
  background-color: var(--magental2);
}
.meet .notificaciones-card-label{
  background-color: var(--turquesal2);
}

.mail .notificaciones-card-label{
  background-color: var(--magental2);
}

.card-notificaciones{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
}
.card-notificaciones {
  flex-direction: column;
  align-items: start;
  gap: 8px;
  padding: 8px 8px;
  width: 100%;
}

.card-notificaciones .tittle{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.card-notificaciones h4{
  font-size: .875rem;
}

.card-tarea.notificacion{
  gap: 0;
}


/* Fichas de notas *//* Fichas de notas *//* Fichas de notas */

.card-notas{
  display: flex;
  background-color: var(--amarillo-alertal3);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0rem .5rem 1.25rem .125rem #E3E3E3;
}
.card-notas hr{
  border-top: .125rem solid var(--amarillo-alertal1);
}


.card-tarea.nota{
  gap: 16px;
}
.card-tarea.notas{
  padding: 8px 16px;
}
.card-tarea.nota{
  gap: 16px;
}
.card-tarea.notas{
  padding: 8px 16px;
}



/* Fichas de documentos *//* Fichas de documentos *//* Fichas de documentos */

.card-documento,
.card-documento .acciones-tarea{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.card-documento .header,
.card-documento .acciones-tarea{
  width: -webkit-fill-available;
}
.card-documento{
  flex-direction: column;
  align-items: start;
  gap: 8px;
  padding: 12px 8px 12px 8px;
  width: 100%;
}
.card-documento .tittle{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.card-documento{
  display: flex;
  background-color: var(--blanco);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0rem .5rem 1.25rem .125rem #E3E3E3;
}
.card-documento hr{
  border-top: .125rem solid var(--gris80);
}

.card-tarea.documento h4{
  color: var(--dark);
  font-weight: 700;
  font-size: 18px;
}
.acciones-tarea.documento {
  justify-content: end;
}
.documento-cargado .enlace{
  gap: 16px;
}
.documento-cargado button{
  background-color: var(--gris-claro3);
  padding: 4px 8px;
  width: fit-content;
  border-radius: 0;
}
.documento-cargado button:hover{
  background-color: var(--gris-claro);
  transition: ease-in-out ;
}
.documento-cargado .eliminar{
  color: var(--gris-obscuro3);
}
.documento-cargado .eliminar:hover{
  color: var(--gris80);
}
.documento-container{
  border-bottom: var(--gris-claro3) solid .1875rem;
  padding: 16px 0px 8px 0px;
}

/* Fichas de negociacion *//* Fichas de negociacion *//* Fichas de negociacion */


.card-negociacion{
  display: flex;
  background-color: var(--blanco);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0rem .5rem 1.25rem .125rem #E3E3E3;
}
.card-negociacion.perdido{

  background-color: var(--rojo-alertal2);

}
.card-negociacion.perdido  .datos-row .label, .nota .label {
  font-size: 12px;
  color: var(--rojo-alertad2);
}

.card-negociacion.perdido h4 {
  color: var(--rojo-alertad2);
}
.card-negociacion.perdido .desplegable svg {
  color: var(--rojo-alertad2);
}

.card-negociacion.ganado{
  display: flex;
}

.card-negociacion.ganado{
  display: flex;
  padding: 0;
}

.card-negociacion.ganado .card-tarea{
  display: flex;
  padding: 0;
  gap: 0;
}
.card-negociacion.ganado .tittle{
  display: flex;
  padding: 12px 12px 12px 12px;
  background-color: var(--verde-negociacionl3);
}
.card-negociacion.ganado .info{
  display: flex;
  padding: 8px 8px 8px 8px;
}

.card-negociacion.ganado .datos-row .label,
.card-negociacion.ganado svg{
  font-size: 12px;
  font-weight: 700;
  color: var(--verde-negociaciond3);
}
.card-notas hr{
  border-top: .125rem solid var(--amarillo-alertal1);
}


.card-tarea.nota{
  gap: 16px;
}
.card-tarea.notas{
  padding: 8px 16px;
}
.card-tarea.nota{
  gap: 16px;
}
.card-tarea.notas{
  padding: 8px 16px;
}
.card-tarea.negociacion h4{
  color: var(--dark);
  font-weight: 700;
  font-size: 18px;
}
/* Fichas de negociacion EMBUDO *//* Fichas de negociacion EMBUDO *//* Fichas de negociacion EMBUDO */
.card-embudo{
  display: flex;
  background-color: var(--blanco);
  border-radius: 10px;
  overflow: visible;
  box-shadow: 0rem .5rem 1.25rem .125rem #E3E3E3;
}
.card-embudo hr{
  border-top: .125rem solid var(--gris-obscuro2);
}

.card-tarea.embudo .tittle{
  border-bottom: var(--gris-obscuro1) solid .0625rem;
  position: relative;

  padding: .5rem .5rem .5rem 1rem;

}

.card-tarea.embudo .tittle:hover{
  background-color: var(--gris-claro2);
}
.card-tarea.embudo .body{
  padding: .5rem 1rem;

}

.card-tarea.embudo{
  gap: 0;
  padding: 0;

}
.datos-row ul{
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  padding-left: 0rem;
  width: 100%;
}
.datos-row li{
  width: 100%;
  padding: .2rem .2rem;
}
.datos-row li:hover{
  background-color: var(--azul-primario3);
}

.embudo-rectangle{
  height: 4rem;
  position: absolute;
  object-fit: fill;
  right: 99%;
}



/* Tipos de tareas *//* Tipos de tareas *//* Tipos de tareas *//* Tipos de tareas */
.tareas-card-label img{
  width: 1rem;
}
/* Mail */
.mail .tareas-card-label{
  background-color: var(--magenta);
}
.mail a{
  color: var(--magenta) ;
}
/* Llamada */
.llamada .tareas-card-label{
  background-color: var(--azul);
}
.llamada a{
  color: var(--azul) ;
}
/* reunion-virtual */
.reunion-virtual .tareas-card-label{
  background-color: var(--morado);
}
.reunion-virtual a{
  color: var(--morado) ;
}
/* reunion-presencial */
.reunion-presencial .tareas-card-label{
  background-color: var(--turquesa);
}
.reunion-presencial a{
  color: var(--turquesa) ;
}


/* DIRECTORIO SEGUROS INDIVIDUALES< *//* DIRECTORIO SEGUROS INDIVIDUALES< *//* DIRECTORIO SEGUROS INDIVIDUALES< */


.contenedor-directorio{
  display: flex;
  height: 100%;
  gap: 8px;
  flex-direction: column;
}

.directorio-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px;
  background-color: var(--azul-primario3);
  border-radius: 8px;

}
.directorio-header.individuales{
  background-color: var(--turquesal3);
  border: var(--turquesal2) solid .125rem;

}

.directorio-header.individuales h5{
  color: var(--azul-primariod1);
}




/* Estilo para el contenedor del checkbox (puede ser la etiqueta <label>) */
.checkbox-personalizado {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* Estilo para el checkbox-personalizado personalizado */
.checkbox-personalizado::before {
  content: "zz";
  font-size: 1rem;
  left: 0;
  top: 0;
  width: 1.25rem;
  height: 1.25rem;
  border: .125rem solid var(--amarillo-alerta); /* Borde del checkbox-personalizado */
  background-color: #fff; /* Fondo del checkbox-personalizado no seleccionado */
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Cambiar el estilo cuando el checkbox-personalizado está marcado */
input[type="checkbox-personalizado"]:checked + .checkbox-personalizado::before {
  background-color: var(--azul-primario3); /* Fondo del checkbox-personalizado seleccionado */
  border-color: var(--azul-primario); /* Borde del checkbox-personalizado seleccionado */
  content: "\2713";
  font-size: 1rem;
  color: var(--azul-primario);
  font-weight: 800;
}
/* Cambiar el estilo cuando el checkbox-personalizado está marcado */
input[type="checkbox-personalizado"]:checked + .checkbox-personalizado.individuales::before {
  background-color: var(--turquesal3); /* Fondo del checkbox-personalizado seleccionado */
  border-color: var(--turquesa); /* Borde del checkbox-personalizado seleccionado */
  content: "\2713";
  font-size: 1rem;
  color: var(--turquesa);
  font-weight: 800;
}

.accionesTabla{
  opacity: 0;
  transition: opacity 0.3s ease;
}

.visible{
  opacity: 100%;
}

/* DIRECTORIO SEGUROS CORPORATIVOS< *//* DIRECTORIO SEGUROS CORPORATIVOS< *//* DIRECTORIO SEGUROS CORPORATIVOS< */
.corporativos-header h5{
  color: var(--azul-primariod1);
}

.directorio-header.corporativos{
  background-color: var(--moradol4);
  border: var(--moradol3) solid .125rem;

}

.table.corporativos tbody tr:hover {
  background-color: var(--moradol3) /* Cambia el color de fondo en hover */
}

.table.corporativos tbody tr.selected {
  background-color: var(--moradol4); /* Cambia el color de fondo cuando está seleccionada */
  border: .1875rem solid var(--moradol2); /* Agrega el borde a la fila seleccionada */
}

/* Estilo para el hover de las opciones de select */
.table.corporativos tbody tr:hover td {
  background-color: var(--moradol4); /* Cambia el color de fondo en hover */
}

/* Cambiar el cursor al pasar sobre una fila */
.table {
  width: inherit;
  margin-bottom: 16px;
  background-color: transparent;
}

.table.corporativos th {
  background-color: var(--moradol3); /* Color de fondo de las cabeceras */
  min-width: auto !important;
  white-space: nowrap;
}


.table thead th {
  vertical-align: middle;
  border-bottom: .125rem solid #dee2e6;
}

/* Cambiar el estilo cuando el checkbox-personalizado está marcado */
input[type="checkbox-personalizado"]:checked + .checkbox-personalizado.corporativos::before {
  background-color: var(--moradol3); /* Fondo del checkbox-personalizado seleccionado */
  border-color: var(--morado); /* Borde del checkbox-personalizado seleccionado */
  content: "\2713";
  font-size: 1rem;
  color: var(--morado);
  font-weight: 800;
}




#tarjetasMañana {
  display: none; /* Oculta el contenedor por defecto */
}

.rotated {
  transform: rotate(90deg);
  transition: transform 0.3s ease; /* Agregar una transición suave */
}

/* EMBUDO DE VENTAS *//* EMBUDO DE VENTAS *//* EMBUDO DE VENTAS */

.col-funel-container{
  height: inherit;
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;

}
.col-funel-container .row{
  margin: 0 !important;
}
.funel-header{
  background-color: var(--verde-negociacionl4);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px;
  height: 10%;
  border-radius: 8px;
  border: var(--verde-negociacionl1) solid .125rem;
}
.funel-header h5{
  color: var(--verde-negociaciond3);
}

.barra-filtros{
  background-color: var(--blanco);
  padding: 8px;
  border: var(--gris-obscuro1) .0625rem solid;
  border-radius: 8px;
  height: 10%;
}
/* FUNEL DE NEGOCIACIONES */

.funel{
  height: 78%;
  display: flex;
  gap: 24px;
}
.funel-columna{
  background-color: var(--blanco);
  border-radius: 8px;
  padding-right: 0;
  padding-left: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  height: 100%;
}

.funel-columna-header{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  position: relative;
}
.funel-columna-header p{
  font-size: 12px;
}
.icono-funel-header{
  position: absolute;
  right: 8px;
  top: 40%;

}
.funel-columna-body{
  padding: 8px 16px ;
  overflow-y: scroll;
  height: 80%;


}
/* Fondos etapas */
.funel-columna-header.etapa1{
  background-color: var(--verde-negociacionl5);
}
.funel-columna-header.etapa2{
  background-color: var(--verde-negociacionl4);
}
.funel-columna-header.etapa3{
  background-color: var(--verde-negociacionl3);
}
.funel-columna-header.etapa4{
  background-color: var(--verde-negociacionl2);
}
.funel-columna-header.etapa5{
  background-color: var(--verde-negociacionl1);
}

/* CARDS DE NEGOCIACIONES */
.card-negociaciones{
  border-radius: 8px;
  margin: 12px 0;
  overflow: hidden;
}
.card-negociaciones.individuales{
  border: .125rem solid var(--turquesal2);
  /* border-top: .625rem; */
}
.card-negociaciones.empresas{
  border: .125rem solid var(--moradol3);
  /* border-top: .625rem; */
}

.card-negociaciones-header{
  background-color: var(--gris-claro2);
  padding: 8px;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
}
.card-negociaciones-body{
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  padding: 8px;
}
.card-negociaciones.individuales .card-negociaciones-header:hover{
  background-color: var(--turquesal2);
}
.card-negociaciones.empresas .card-negociaciones-header:hover{
  background-color: var(--moradol3);
}




.rotated {
  transform: rotate(90deg);
  transition: transform 0.3s ease; /* Agregar una transición suave */
}



/* Cliente individual *//* Cliente individual *//* Cliente individual *//* Cliente individual */


/* Columna datos cliente */
.contenedor-cliente-individual{
  display: flex;
  height: 100%;
  gap: 8px;
  flex-direction: column;
}

.header.cliente-individual{
  background-color: var(--blanco);
  height: auto;
  border: var(--gris-obscuro2) solid .0625rem;
}

.cliente-individual-columnas{
  display: flex;
  gap: 16px;
  height: 94%;
}
.col-container.datos{
  max-width: 25%;
}
.datos-cta{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
  padding: 16px 16px;
}
.datos-container{
  padding: 0px 8px;
  overflow-y: scroll;
  height: inherit;

}

/* Columna prospección *//* Columna prospección *//* Columna prospección */
.col-header.prospeccion{
  background-color: var(--turquesal3);
  border-bottom: var(--turquesal1) solid .0625rem;
}
.col-container.prospeccion{
  border: var(--turquesal1) solid .0625rem;
  max-width: 45%;
}
.cards-container.prospeccion{
  padding: 8px 4px;
  height: inherit;
}



/* Tabs Tabs Tabs Tabs */

.tabs-container.prospeccion {
  width: 100%;
  height: 88%;
}
.tab-content{
  height: inherit;
}
.tab-pane{
  height: inherit;
}
.col-cards {
  height: inherit;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: rgba(243, 11, 11, 0.918) transparent !important;
  padding: 8px 4px;
  background-color: var(--gris-claro);
}
.nav-link.active.prospeccion{
  color: #495057;
  background-color: var(--gris-claro2) !important;
  border-color: var(--gris-claro2) !important;
  border-bottom: var(--azul-primariol1) .1875rem solid !important;
  border-radius: 8px 8px 0px 0px;
}


.nav-link.prospeccion:hover{
  background-color: var(--gris-claro) !important;
}
.nav-link.prospeccion:hover p{
  color: var(--gris80) !important;
}
.nav-link.prospeccion {
  min-height: 40px;
}

.nav-link.prospeccion p{
  text-align: center;
  word-wrap: break-word;
}



/* Columna NEGOCIACION *//* Columna NEGOCIACION *//* Columna NEGOCIACION *//* Columna NEGOCIACION */

.col-header.negociacion{
  background-color: var(--verde-negociacionl4);
  border-bottom: var(--verde-negociacionl1) solid .125rem;
}
.col-container.negociacion{
  border: var(--verde-negociacionl1) solid .0625rem;
  max-width: 35%;
}

/* Cars negociaciones *//* Cars negociaciones *//* Cars negociaciones *//* Cars negociaciones */

.card-negociacion,
.card-negociacion .info,
.card-negociacion .iconos-editar-borrar,
.card-negociacion .acciones-negociacion,
.negociacion-completada{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.card-negociacion {
  flex-direction: column;
  align-items: start;
  gap: 8px;
  padding: 12px 8px 12px 8px;
  width: 100%;
}
.card-negociacion .header,
.card-negociacion .acciones-negociacion{
  width: -webkit-fill-available;
}
.card-negociacion .tittle{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.card-negociacion h4{
  font-size: .875rem;
}
.modal.negociacion .close{
  color: var(--verde-negociaciond3);
}
/* Termina Columna NEGOCIACION *//* Termina Columna NEGOCIACION *//* Termina Columna NEGOCIACION */


.rotated {
  transform: rotate(90deg);
  transition: transform 0.3s ease; /* Agregar una transición suave */
}

.rotable{
  transform: rotate(90deg);
  transition: transform 0.3s ease;
}
.rotable2{
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.background{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* color de fondo semitransparente */
  display: none; /* inicialmente oculto */
  z-index: 99;
  transition: transform 0.3s ease;
}


.background.desplegado{
  display: block;
  transition: transform 0.3s ease;
}



.opcion-label {
  display: inline-block;
  cursor: pointer;
  padding: .625rem 1.25rem;
  margin: .3125rem;
  border: .0625rem solid #ccc;
  border-radius: .3125rem;
}

/* Estilos cuando el label está seleccionado */
.selecciona-seguro-cobertura input[type="radio"] {
  display: inline !important;
  /* -webkit-appearance: auto!important;
     -moz-appearance: auto !important; */
  appearance: auto !important;
}
.selecciona-seguro-cobertura input[type="radio"]:checked + label {
  background-color: #4CAF50;
  color: white;
  border-color: #4CAF50;
}

.selecciona-seguro-cobertura input[type="radio"]:checked  {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 3.75rem;
  height: 1.25rem;
  border-radius: 50%;
  border: .125rem solid #4CAF50 !important; /* Cambia el color del borde a verde */
  background-color: #4CAF50 !important; /* Cambia el color de fondo a verde */
}


.nav-link.aseguradora-cobertura {
  display: flex !important;
  /* flex-direction: column !important; */
  align-items: center !important;
  justify-content: start !important;
  /* flex-wrap: wrap; */
  color: var(--gris-obscuro2);
  font: 600 .75rem/normal DM Sans;
  padding:8px 32px !important;
  width: max-content;
  /* max-width: 7.5rem; */
  /* min-width: 5.625rem; */
  /* min-height: 5rem; */
  gap: 32px;
  transition: all .3s ease-in-out;

}
.nav-link.aseguradora-cobertura.active{
  color: #495057;
  background-color: var(--azul-primario4) !important;
  border-color: var(--azul-primario3) !important;
  border-bottom: var(--azul-primariol1) .125rem solid !important;
  border-radius: 8px 8px 0px 0px;
  padding: 8px 32px !important;
}


.nav-link.aseguradora-cobertura {
  min-height: 40px!important;
}

.nav-link.aseguradora-cobertura p{
  text-align: center;
  word-wrap: break-word;
}


/* CONFIGURACION *//* CONFIGURACION *//* CONFIGURACION */
.col-container.configuracion{
  height: 100%;
  border-radius: 8px;
  border: var(--gris-obscuro1) solid .125rem;
  max-width: 15%;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden;
  background-color: var(--gris-claro)
}


.col-header{
  background-color: var(--blanco);
  border-bottom: solid .125rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 22px 8px ;
  width: 100%;
}
.col-header p{
  font-size: 12px;
}
.col-header.configuracion{
  background-color: var(--gris-claro2);
  border-bottom: var(--gris-obscuro1) solid .125rem;
  height: 5%;
  padding: 1rem .5rem;

}

.col-configuracion{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: space-between;

}
.col-configuracion .row{
  margin: 0 !important;
}




/* Resumen ventas *//* Resumen ventas *//* Resumen ventas */

.header.configuracion{
  background-color: var(--gris-claro2);
  border: var(--gris-obscuro1) solid 0.125rem;
  height: 8%;
}
.contenido-configuracion{
  border-radius: 8px;
  border: var(--gris-obscuro1) solid .125rem;
  margin: 0 !important;
  padding: 1rem;
  overflow: hidden;
  background-color: var(--blanco);
  height: 100%;
  width: 100%;
}
.contenido-configuracion.pestanas{
  border-radius: 0px 8px 8px 08px;

}
.contenido-configuracion-header{
  background-color: var(--blanco);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;

}
.contenido-configuraacion-datos{
  height: 80%;
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 16px;
  gap: 16px;
  overflow-y: scroll;
}
.contenido-configuraacion-datos .row{
  width: 100%;
}
.contenido-configuraacion-card{
  background-color: var(--blanco);
  padding: 16px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 200px;
}
.cards-container.configuracion{
  width: 100%;
  padding:  0;
}




.user-avatar{
  width: 4rem;
  border-radius: .75rem;
}
.leyenda{
  font-size: 12px;
  color: var(--gris80);
}


.tabla-container.configuracion{
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  height: auto;
  width:100% ;
  gap: .5rem;
  padding: 1rem 0rem;
}

.tabla-container.configuracion.embudo{
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  height: auto;
  width:fit-content ;
  gap: 1rem;
}



.table-container.configuracion{
  max-height: 250px;
  overflow-y: scroll;
}
.table-container.configuracion.embudo{
  max-height: 400px;
  overflow-y: scroll;
}
.table.configuracion th{
  width: 250px !important;
}
.table.configuracion.embudo th{
  width: 300px !important;

}

.nav-link.configuracion {
  display: flex !important;
  /* flex-direction: column !important; */
  align-items: center !important;
  justify-content: start !important;
  /* flex-wrap: wrap; */
  color: var(--gris-obscuro3);
  font: 600 .75rem/normal DM Sans;
  padding: 1rem 4rem !important;
  width: max-content;
  /* max-width: 7.5rem; */
  /* min-width: 5.625rem; */
  /* min-height: 5rem; */
  gap: 32px;
  transition: all .3s ease-in-out;
  border: 1px solid var(--gris-obscuro1);
  border-radius: 8px 8px 0px 0px;
  background-color: var(--gris-claro2);

}
.nav-link.active.configuracion{
  color: #495057;
  background-color: var(--blanco) !important;
  border-color: var(--gris-claro2) !important;
  border-bottom: var(--azul-primariol1) 0.1875rem solid !important;
  border-radius: 8px 8px 0px 0px;
  padding: 1rem 4rem;
}
.nav-item.configuracion {
  display: flex !important;
  /* flex-direction: column !important; */
  align-items: center !important;
  justify-content: start !important;
  /* flex-wrap: wrap; */
  color: var(--gris-obscuro3);
  font: 600 .75rem/normal DM Sans;

  width: max-content;
  /* max-width: 7.5rem; */
  /* min-width: 5.625rem; */
  /* min-height: 5rem; */
  gap: 32px;
  transition: all .3s ease-in-out;

}
.nav-pills .configuracion.nav-item.active {
  border-radius: 0rem;
  background-color: var(--azul-primariol1);
  color: var(--azul-primariod1);
}
.nav-pills .nav-item.configuracion {
  border-radius: 0rem;
  width: 100%;
  padding: .75rem .5rem !important;
}
.nav-pills .nav-item.configuracion:hover {
  background-color: var(--gris-claro4);
}
.nav-pills .nav-item.configuracion.active:hover {
  background-color: var(--azul-primariol1);
}

.nav-pills.nueva-solicitud .nav-item .nav-link{
  background-color: var(--gris-claro2);
  color: var(--gris);
  padding: .5rem;
  font-size: 14px;
  border-radius: 8px;
  font-weight: 400;
  transition: all .1s ease-in-out;
  width: auto;
}


.nav-pills.nueva-solicitud .nav-item .nav-link.active{
  background-color: var(--azul-primariol1);
  color: var(--dark);
  font-weight: 600;
  transition: all .1s ease-in-out;
}

.nav-link2 {
  display: flex !important;
  /* flex-direction: column !important; */
  align-items: center !important;
  justify-content: start !important;
  /* flex-wrap: wrap; */
  color: var(--gris-obscuro3);
  font: 600 .75rem/normal DM Sans;
  padding: 24px 20px !important;
  width: max-content;
  /* max-width: 7.5rem; */
  /* min-width: 5.625rem; */
  /* min-height: 5rem; */
  gap: 32px;
  transition: all .3s ease-in-out;

}

.nav-link2.configuracion {
  display: flex !important;
  /* flex-direction: column !important; */
  align-items: center !important;
  justify-content: start !important;
  /* flex-wrap: wrap; */
  color: var(--gris-obscuro3);
  font: 600 .75rem/normal DM Sans;
  padding: 1rem 4rem !important;
  width: max-content;
  /* max-width: 7.5rem; */
  /* min-width: 5.625rem; */
  /* min-height: 5rem; */
  gap: 32px;
  transition: all .3s ease-in-out;
  border: 1px solid var(--gris-obscuro1);
  border-radius: 8px 8px 0px 0px;
  background-color: var(--gris-claro2);

}
.nav-link2.configuracion:hover{
  background-color: var(--blanco);
}
.nav-link2.active.configuracion{
  color: #495057;
  background-color: var(--blanco) !important;
  border-color: var(--gris-claro2) !important;
  border-bottom: var(--azul-primariol1) 0.1875rem solid !important;
  border-radius: 8px 8px 0px 0px;
  padding: 1rem 4rem;
}

.configuracion-contenedor-productos {
  background-color: var(--gris-claro2);
  padding: 1rem;
  border-radius: 0.5rem;
  border: solid 2px var(--gris-obscuro1);
  display: flex;

  align-items: start;
  justify-content: start;
  height: inherit;
  overflow-y: scroll;
}

.card-producto{
  display: flex;
  background-color: var(--blanco);
  border-radius: 10px;
  overflow: visible;
  box-shadow: 0rem .5rem 1.25rem .125rem #E3E3E3;
}
.card-producto hr{
  border-top: .125rem solid var(--gris-claro2);
}

.card-tarea.producto .tittle{
  border-bottom: var(--gris-obscuro1) solid .0625rem;
  position: relative;

  padding: .5rem .5rem .5rem 1rem;

}


.card-tarea.producto .body{
  padding: .5rem 1rem;

}

.card-tarea.producto{
  gap: 0;
  padding: 0;

}


/* INICIO INICIO INICIO */

.container-dashboard.inicio{
  width: 100%!important;
  padding: 0rem !important;
  gap: 0rem!important;
}

.col-container.inicio{

  border-radius:0px;
  border: none;

  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden;
  background-color: var(--blanco);
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;

}

.col-container.inicio2{
  height: 100%;
  border-radius:0px;
  border: none;

  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden;
  background-color: var(--blanco);
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;

}


.col-resumen-container.inicio{
  height: 100vh !important;
  justify-content: center;
}
.contenedor-inicio{
  max-height: 100vh;
  overflow: hidden;
}
.foto-inicio-container{
  width: 110%;
  overflow: hidden;
}
.foto-inicio{
  width: 100%;
}


.inicio-sesion-contenedor {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  flex-direction: column;
  gap: 2rem;
  background-color: var(--blanco);
  position: relative;
  padding: 2rem 2rem;
}

.container-fluid.inicio-sesion-contenedor{
  margin: 0 !important;
  padding: 0 !important;
}
.logotipo{
  max-width: 12rem;
}
@media (max-width: 1200px) {
  .logotipo{
    max-width: 8rem;
  }
}

.barra-progreso{
  max-width: 50rem;
}

.inicio-sesion-header{
  display: flex;

  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 15%;
}

.inicio-sesion-body{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  overflow: hidden;
}
.inicio-sesion-footer{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  padding: 2rem ;


}
.inicio-sesion-body .form-row{

  width: 100%;
}

.checkbox{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: .5rem;
  font-size: .75rem;
}
.checkbox::before {
  content: "";
  font-size: .75rem;
  left: 0;
  top: 0;
  width: 1rem;
  height: 1rem;
  border: .125rem solid var(--gris-obscuro3); /* Borde del checkbox */
  background-color: #fff; /* Fondo del checkbox no seleccionado */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
}
/* Cambiar el estilo cuando el checkbox está marcado */
input[type="checkbox"]:checked + .checkbox::before {
  background-color: var(--azul-primario3); /* Fondo del checkbox seleccionado */
  border-color: var(--azul-primario); /* Borde del checkbox seleccionado */
  content: "\2713";
  font-size: .75rem;
  color: var(--azul-primario);
  font-weight: 800;
}
.datos-row.inicio li{
  padding: 0rem 0.2rem;
}

.enlace-lg {
  position: absolute;
  left: 1rem;
  top:2rem;
  cursor: pointer;
}

.form-control.ammiaAdmin-style.inicio{
  height: 3rem;
  font-size: 3rem;
  min-width: auto;
  width: 3rem;
  text-align: center;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 30px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--azul-primario);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--azul-primario);
}

input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.configuracion-switch-contenedor
{ background-color: var(--gris-claro3);
  overflow-y: scroll;
  height: 320px;
  padding: 1rem;
}
.configuracion-switch{
  background-color: var(--gris-claro2);
  padding: .5rem 1rem;
  width: 100%;
  border: solid 1px var(--gris-obscuro1);
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: ease-in-out .2s;

}

.configuracion-switch-white{
  background-color: var(--blanco);
  padding: .5rem 1rem;
  width: 100%;
  border: solid 1px var(--gris-obscuro1);
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: ease-in-out .2s;
}
.configuracion-switch p{
  font-size: .875rem;
  color: var(--gris80);
}.configuracion-switch-white p{
   font-size: .875rem;
   color: var(--dark);
 }
.offcanvas.offcanvas-end{
  width: auto;
  padding-left: 0;
  padding-right: 0;
  max-width: 500px;
}
.offcanvas.offcanvas-end.hacer-solicitud{
  width:640px;
  padding-left: 0;
  padding-right: 0;
  max-width:640px;
}

@media (max-width:768px) {
  .offcanvas.offcanvas-end{
    width: auto;
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;
  }
}
.offcanvas.offcanvas-end.hacer-solicitud{

  max-width:100%;
}
.link-titulo h4:hover{
  color: var(--gris80);
  text-decoration: underline;
}
.offcanvas-footer{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0rem 0rem;
  padding: 2rem;
  border-top: 1px var(--gris-claro2) solid;
}


.solicitud-parrafo{
  font-size: 14px;
}

.nueva-solicitud-programas{
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  row-gap: 2rem;
  width: 100%;
}

@media (max-width:556px){
  .nueva-solicitud-programas{
    flex-direction: column;
    grid-template-columns: 1fr;
    justify-items: center;
  }
}

.poliza-vehiculo-card{
  padding: 2rem 1.5rem;
  background-color: var(--gris-claro);
  border: 1px solid var(--gris);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: .5rem;
}

.poliza-vehiculo-card h5{
  font-size: 16px;
  color: var(--dark);
  font-weight: 600 !important;
}
.poliza-vehiculo-card h6{
  color: var(--gris-obscuro2);
  font-size: 14px;
}

.receipt-detail .head {
  color: #448AA6;
  font-size: 14px;
  font-weight: 500;
}
.chart-container {
  position: relative;
  margin: auto;
  height: 350px;/*350*/
  width: 100%;
}
/******dropdown*****/
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: dashed;
  border-right: solid transparent;
  border-left: solid transparent;
}
.dropup, .dropdown {
  position: relative;
}
.dropdown-toggle:focus {
  outline: 0;
}
.dropdown-menu {
  /*position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid ;
  border-radius: 4px;*/

  max-height: 25rem;
  min-width: 55rem;
  width: auto;
  overflow-y: auto;
  border-radius: .5rem;
  padding: 1rem .5rem;
  border: none;
  box-shadow: 3px 3px 27px 0px rgba(88, 105, 131, 0.30);

  inset: 0px auto auto 0px;
  margin: 0px;
  transform: translate(-579px, 41px);
}
.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}
.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}
.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
  color: #fff;
  text-decoration: none;
  background-color: #337ab7;
}
.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
  color: #fff;
  text-decoration: none;
  background-color: #337ab7;
  outline: 0;
}
.dropdown-menu > .disabled > a, .dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
  color: #337ab7;
}
.dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
  text-decoration: none;
  cursor: auto;
  background-color: transparent;
  background-image: none;
}
.open > .dropdown-menu {
  display: block;
}
.open > a {
  outline: 0;
}
.dropdown-menu-right {
  right: 0;
  left: auto;
}
.dropdown-menu-left {
  right: auto;
  left: 0;
}
.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #fff;
  white-space: nowrap;
}
.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}
.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}
.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: dashed;
}
.dropup .dropdown-menu, .navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}
@media (min-width: 768px) {
  .navbar-right .dropdown-menu {
    right: 0;
    left: auto;
  }
  .navbar-right .dropdown-menu-left {
    right: auto;
    left: 0;
  }
}
